import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import PackagesCom from 
'../Components/Packages';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';


export default function Packages(){
  
  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Packages';
},[]);
	return(
    <> 
    <Header />
    
    <section id="social-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>Our Packages Are Strategically Planed To Deliver Max ROI</h1>
                <p className="text-white">Today as technology is progressing and the world of business is benefiting
                  from it, we know that websites have become an essential part of modern business. But if your
                  website is not handled by experts, it is only an expense. As competition is increasing on
                  the web, Website services are getting more expensive.</p>
                <p className="text-white">To make sure that website services are affordable for any business, we
                  have planned multiple packages to cater to any budget and deliver maximum value per budget.
                </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">

              <All_BannerForm />

            </div>
          </div>
        </div>
    </section>

     <PackagesCom/>
     <Contact/>

    <Footer />
    </>

		)
}