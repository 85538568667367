import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';




export default function Shopify(){

  setTimeout(() => {
    document.getElementById('shopify').click();
    document.getElementById('nav-shopfiy-tab').click()
  }, 1000);

  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Shopify';
},[]);

	return(
    <> 
    <Header />
    
    <section id="local-seo-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center aos-init aos-animate" data-aos="fade-down" data-aos-duration={1000}>
                <h1>Shopify Website Let's Break Into E-Commerce</h1>
                <p className="text-white">Shopify is the ultimate eCommerce solution for new as well as established
                  businesses. For years, Web Craft Solutions has helped brick-and-mortar stores break through
                  the eCommerce world through robust Shopify web solutions
                </p>
                <p className="text-white">Local SEO optimization can help you reach out to some of the most
                  promising and potential buyers and we can help you with the process. It’s time to succeed.
                </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive aos-init aos-animate" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              < All_BannerForm/>
            </div>
          </div>
        </div>
      </section>


     <Packages/>
     <Portfolio/>

     <section className="about-us-sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-7 wow fadeInLeft" data-wow-duration="1s" style={{visibility: 'visible', animationDuration: '1s', animationName: 'fadeInLeft'}}>
              <div className="hd-txt">
                <h2><br />Our Shopify Experts Bring Ideas to Life!</h2>
                <p>
                  Whether it's a fresh startup concept or a renovation of your existing eCommerce website, our
                  Shopify Development Services will cater to all the needs to build up or escalate an online
                  store. As a top Shopify Development Company, we help you construct intuitive, sales-driven,
                  and robust online stores. We have got a team of 50+ Shopify developers. Web Craft Solutions
                  are specialists in developing Shopify websites from scratch and transforming existing
                  websites into modern and customer-driven websites that enhance the store's user engagement
                  and credibility. Our Shopify Design and Development Services include quick corrections and
                  upgrades to any work completed by our Shopify professionals.
                </p>
                <div className="bnr-btn about-us-btn wow fadeInUp" data-wow-duration="2s" style={{visibility: 'visible', animationDuration: '2s', animationName: 'fadeInUp'}}>
                  <a className="theme-btn light-gradient" href="javascript:;" data-toggle="modal" data-target="#analyse-modal">Request a Quote </a>
                  <a href="javascript:;" className="livechatbtn">live chat <i className="fa fa-arrow-circle-o-right" aria-hidden="true" /> </a>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <img src="images/shopify-img-after-banner.png" className="hiddex-xs img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="hd-txt wow fadeInUp" data-wow-duration="1s">
                <h2>Our Shopify Web Design Services</h2>
                <p>Web Craft Solutions follows a strategic and flexible approach that is adapted to the needs of
                  the client. We continuously strive to provide the most appropriate eCommerce Shopify
                  solutions for your company. For Shopify web development services, our in-house professionals
                  follow an agile workflow and provide customized web solutions with a strong focus on the
                  customer requirements</p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="why-slider">
                <div className="why-slider-choose wow fadeInLeft row justify-content-center" data-wow-duration="1s">
                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-1.png" />
                      <h4>e-Store <br />Customization</h4>
                      <p>e-Store Customization Want to add more features and abilities to your existing
                        Shopify e-Store? We provide custom eCommerce solutions that are specific to your
                        business niche.</p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-2.png" />
                      <h4>App <br />Integrations</h4>
                      <p>In order to add more functionality to the store, we integrate applications such
                        as payment gateways, Enterprise Resource Planning, Inventory Management, and
                        much more. </p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-3.png" />
                      <h4>Shopify Store <br />Maintenace</h4>
                      <p>Our Shopify web experts will help you with any technical issue that your website
                        faces and will boost the overall performance to ensure a seamless experience.
                      </p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-4.png" />
                      <h4>Comprehensive <br />Shopify Services</h4>
                      <p>Our tailored Shopify solutions will help your company achieve its eCommerce
                        objectives. From Shopify store development to maintenance, we have you covered.
                      </p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-1.png" />
                      <h4>Complete <br />E-Commerce Solutions</h4>
                      <p>Whether it's Shopify installation, configuration, or integration, our tailored
                        solutions may help your company achieve its eCommerce objectives exactly how you
                        want them.</p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-3.png" />
                      <h4>Online <br />Payment Integration</h4>
                      <p>AWC integrates payment gateways, special functions, third-party apps, and APIs to
                        generate a systematic payment flow process. </p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-4 my-3'>
                    <div className="why-box">
                      <img loading="lazy" src="images/why-icon-4.png" />
                      <h4>Shopify <br />Maintenance Services</h4>
                      <p>Our Shopify specialists will be helping you with a technical issue, whether it's
                        a little problem or boosting the overall speed of your Shopify Store. </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Contact/>

    <Footer />
    </>

		)
}