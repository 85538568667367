
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export default function Contact() {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8iuyr9c', 'template_t3nsxpn', form.current, 'mFs_PVy5BMJMtOR91')
      .then((result) => {
          document.getElementById("footer_form").reset();
          alert('Submit Successfully.');
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  return (

    <section className="logo-rotate4 get_consultancy_sec all-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 center_web_sec">
            <div className="web_content_Sec text-center aos-init aos-animate" data-aos="fade-down" data-aos-duration={1000}>
              <h3 className="section-head text-white">Connect To Expand Online</h3>
              <p className="text-white">If you want to see your business expand on the internet then send us an
                email by using the form below.</p>
            </div>
            <div className="consultancy_form">
              <form className="form-get-quote" data-aos="fade-right" data-aos-duration={1000} id="footer_form" ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-sm-6">
                    <input type="text" name="user_name" className="form-control" required placeholder="Full Name*" />
                  </div>
                  <div className="col-sm-6">
                    <input type="email" name="user_email" className="form-control" required placeholder="Email*" />
                  </div>
                  <div className="col-sm-6">
                    <input type="text" name="user_phone" className="form-control phoneNum" required placeholder="Phone*" />
                  </div>
                  <div className="col-sm-6">
                    <input type="text" name="user_country" className="form-control" required placeholder="Country*" />
                  </div>
                  <div className="col-md-12">
                    <textarea name="message" required placeholder="Message" className="form-control" defaultValue={""} />
                  </div>
                  <div className="col-md-12">
                    <div className="form_btn">
                      <input className="orange-gradient theme-btn border-0 text-white text-uppercase" type="submit" defaultValue="Contact Our Team" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}