import { Link } from 'react-router-dom';


export default function Header() {

  function openNav() {
    document.getElementById("mySidenav").style.left = "0px"
  }

  function closeNav() {
    document.getElementById("mySidenav").style.left = "-250px"
  }

  return (
    <>

      <header id="header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-2 col-3">
              <div className="logo-main-sec">
                <a href="home">
                  <img src="images/logo.png" className="img-responsive" alt="Web Craft Solutions" style={{}} />
                </a>
              </div>
            </div>
            <div className="col-md-8 col-sm-7 col-9 d-lg-none d-block">
              <ul className="header-extra float-right d-flex align-items-center">
                <li>
                  <i className="fa fa-phone" aria-hidden="true" />
                  <a href="tel:+1234567891">1234567891</a>
                </li>
                <li>
                  <a data-toggle="modal" data-target="#analyse-modal" className="light-gradient theme-btn" href="javascript:;">Get a Free Quote</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-10 col-2">
              <nav className="navbar navbar-expand-lg navbar-light float-right">
                <div className="menu-sec-main float-right">
                  <div className="sidenav dark-gradient" id="mySidenav">
                    <a className="closebtn" href="javascript:void(0)" onClick={closeNav}>×</a>
                    <ul className="navbar-nav align-items-center">
                      <li><Link className="head-link" to="/home">Home</Link></li>
                      <li><Link className="head-link" to="/about">About</Link></li>
                      <li className="dropdown">
                        <a className=" head-link dropdown-toggle" href="javascript:;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services </a>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><a href="logo-design" className="dropdown-item">Logo Design</a></li>
                          <li><a href="website-development" className="dropdown-item">Website
                            Development</a></li>
                          <li><a href="ecommerce" className="dropdown-item">Ecommerce Development</a>
                          </li>
                          <li><a href="wordpress" className="dropdown-item">Wordpress</a></li>
                          <li><a href="shopify" className="dropdown-item">Shopify</a></li>
                          <li><a href="video-nimation" className="dropdown-item">Video Animation</a>
                          </li>
                          <li><a href="social-media-marketing" className="dropdown-item">Social Media
                            Marketing</a></li>
                          <li><a href="search-engine-optimization" className="dropdown-item">SEO
                            Optimization</a></li>
                          <li><a href="content-marketing" className="dropdown-item">Content
                            Marketing</a></li>

                        </ul>
                      </li>
                      <li><a className="head-link" href="portfolio">Portfolio </a></li>
                      <li><a className="head-link" href="packages">Packages </a></li>
                      <li><a className="head-link" href="contact">Contact Us</a></li>
                      <li><a className="head-link" href="faq">FAQ's </a></li>
                    </ul>
                  </div>
                  <div className="mobilecontainer d-lg-none d-block">
                    <span className="float-right dark-gradient" onClick={openNav} style={{ fontSize: '30px', cursor: 'pointer' }}>☰</span>
                  </div>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="main-nav navbar-nav align-items-center">
                      <li><a className="head-link" href="home">Home</a></li>
                      <li><a className="head-link" href="about">About </a></li>
                      <li className="dropdown">
                        <a className=" head-link dropdown-toggle" href="javascript:;">Services </a>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li>
                            <a href="logo-design" className="dropdown-item"><img src="images/drp-ecommerce.png" alt="design" />Logo Design</a>
                          </li>
                          <li>
                            <a href="website-development" className="dropdown-item"><img src="images/drp-web%20content.png" alt="website" />Website
                              Development</a>
                          </li>
                          <li>
                            <a href="ecommerce" className="dropdown-item"><img src="images/drp-ecommerce.png" alt="ecommerce" />Ecommerce
                              Development</a>
                          </li>
                          <li>
                            <a href="shopify" className="dropdown-item"><img src="images/drp-ecommerce.png" alt="shopify" />Shopify</a>
                          </li>
                          <li>
                            <a href="wordpress" className="dropdown-item"><img src="images/drp-ecommerce.png" alt="wordpress" />WordPress</a>
                          </li>
                          <li>
                            <a href="video-animation" className="dropdown-item"><img src="images/drp-ecommerce.png" alt="animation" />Video
                              Animation</a>
                          </li>
                          <li>
                            <a href="search-engine-optimization" className="dropdown-item"><img src="images/drp-local%20seo.png" alt="seo" />SEO Optimization</a>
                          </li>
                          <li>
                            <a href="social-media-marketing" className="dropdown-item"><img src="images/drp-social%20media.png" alt="social" /> Social Media
                              Marketing</a>
                          </li>
                          <li>
                            <a href="content-marketing" className="dropdown-item"><img src="images/drp-content%20marketing.png" alt="content" />Content
                              Marketing</a>
                          </li>
                          {/*<li>*/}
                          {/*    <a href="service-link" class="dropdown-item"><img src="images/drp-link.png" alt="">Link Building</a>*/}
                          {/*</li>*/}
                          {/* <li>
                                                <a href="whitelabel" class="dropdown-item"><img
                                                        src="images/drp-white%20label%20reseller%20program-01.png"
                                                        alt="reseller">White-Label Reseller Program</a>
                                            </li> */}
                        </ul>
                      </li>
                      <li><a className="head-link" href="portfolio">Portfolio</a></li>
                      <li><a className="head-link" href="packages">Packages </a></li>
                      <li><a className="head-link" href="contact">Contact Us</a></li>
                      <li><a className="head-link" href="faq">FAQ's </a></li>
                    </ul>
                    <ul className="header-extra float-right d-flex align-items-center">
                      <li>
                        <i className="fa fa-phone" aria-hidden="true" />
                        <a href="tel:+1234567891">1234567891</a>
                      </li>
                      <li>
                        <a data-toggle="modal" data-target="#analyse-modal" className="light-gradient theme-btn" href="javascript:;">Get a Free Quote</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div id="myModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <section className="section-padding gray_bg get_quote">
            <div className="row">
              <div className="col-md-12">
                <h3 id="heading" className="text-center">We would like to hear from you</h3>
                <p className="text-center grey">
                  Heads up! We require that you sign up for Weblogies services and packages. We make all your
                  dreams come true in a successful project.
                </p>
                <form id="package_form" className="form-get-quote" method="POST" action="javascript:;">
                  <div className="step-form">
                    <section data-view={1}>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12 margin-bottom-20 field-mergedright">
                          <input type="text" autoComplete="off" className="form-control" placeholder="Full Name" name="full_name" required="required" />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 margin-bottom-20 field-mergedleft">
                          <input type="email" autoComplete="off" className="form-control" placeholder="Email" name="email" required="required" />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 margin-bottom-20 field-mergedright">
                          <input type="text" autoComplete="off" className="form-control phoneNum" placeholder="Contact Number" name="phone" required="required" />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 margin-bottom-20 field-mergedright">
                          <select name="budget_range" id="form-your-budget" aria-invalid="false" required className="form-control">
                            <option value selected disabled>Budget range</option>
                            <option value="less than $1000">less than $1000</option>
                            <option value="from $1000 to $5000">from $1000 to $5000</option>
                            <option value="from $5000 to $15000">from $5000 to $15000</option>
                            <option value="from $15000 to $50000">from $15000 to $50000</option>
                            <option value="from $50 000 to $100000">from $50000 to $100000</option>
                            <option value="more than $100000">more than $100000</option>
                          </select>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12 margin-bottom-20 field-mergedleft">
                          <select name="package_name" id="packages" className="valid safari_only form-control" aria-invalid="false" required>
                            <option value pack={0}>Package Name</option>
                            <optgroup className="pack_select">
                              <option disabled value pack={0} className="colr">SEARCH ENGINE
                                OPTIMIZATION</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="SEO - Kick Starter Package  - $199/m" pack={1}>SEO -
                                Kick Starter Package - $199/m</option>
                              <option value="SEO - Start-up Package - $349/m" pack={2}>SEO -
                                Start-up Package - $349/m</option>
                              <option value="SEO - Professional Package - $749/m" pack={3}>SEO -
                                Professional Package - $749/m</option>
                            </optgroup>
                            <optgroup className="pack_select">
                              <option disabled value pack={0} className="colr">SOCIAL MEDIA
                                MARKETING</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="SMM - Kick Starter Package  - $199/m" pack={4}>SMM -
                                Kick Starter Package - $199/m</option>
                              <option value="SMM - Scaling Plan - $349/m" pack={5}>SMM - Scaling
                                Plan - $349/m</option>
                              <option value="SMM - Venture Plan - $699/m" pack={6}>SMM - Venture
                                Plan - $699/m</option>
                            </optgroup>
                            <optgroup className="pack_select">
                              <option disabled value pack={0} className="colr">WEB CONTENT
                              </option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Web Content - Startup Plan - $199/m" pack={10}>Web
                                Content - Kick Starter Package - $199/m</option>
                              <option value="Web Content - Scaling Plan - $399/m" pack={11}>Web
                                Content - Scaling Package - $399/m</option>
                              <option value="Web Content - Venture Plan - $699/m" pack={12}>Web
                                Content - Venture Plan - $699/m</option>
                            </optgroup>
                            <optgroup className="pack_select">
                              <option disabled value pack={0} className="colr">LINK BUILDING
                              </option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Link Building - Kick Starter Package  - $199/m" pack={13}>Link Building - Kick Starter Package - $199/m</option>
                              <option value="Link Building - Scaling Plan - $399/m" pack={14}>Link
                                Building - Scaling Plan - $399/m</option>
                              <option value="Link Building - Venture Plan - $699/m" pack={15}>Link
                                Building - Venture Plan - $699/m</option>
                            </optgroup>
                            <optgroup className="pack_select">
                              <option disabled value pack={0} className="colr">BLOG POST
                              </option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Blog Post - Startup Plan - $69.75/m" pack={34}>Blog
                                Post - Startup Plan - $69.75/m</option>
                              <option value="Blog Post - Startup Plan - $115/m" pack={16}>Blog
                                Post - Startup Plan - $115/m</option>
                              <option value="Blog Post - Scaling Plan - $119.5/m" pack={35}>Blog
                                Post - Scaling Plan - $119.5/m</option>
                              <option value="Blog Post - Scaling Plan - $200/m" pack={17}>Blog
                                Post - Scaling Plan - $200/m</option>
                              <option value="Blog Post - Venture Plan - $164.25/m" pack={36}>Blog
                                Post - Venture Plan - $164.25/m</option>
                              <option value="Blog Post - Venture Plan - $900/m" pack={18}>Blog
                                Post - Venture Plan - $900/m</option>
                            </optgroup>
                            <optgroup>
                              <option disabled value pack={0} className="colr">Infographic
                                Package</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Infographic Package - Startup Plan - $600" pack={19}>
                                Infographic Package - Startup Plan - $600</option>
                              <option value="Infographic Package - Scaling Plan - $1200" pack={20}>Infographic Package - Scaling Plan - $1200</option>
                              <option value="Infographic Package - Venture Plan - $1800" pack={21}>Infographic Package - Venture Plan - $1800</option>
                            </optgroup>
                            <optgroup>
                              <option disabled value pack={0} className="colr">Product
                                Description Package</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Product Description - Startup Plan - $300" pack={22}>
                                Product Description - Startup Plan - $300</option>
                              <option value="Product Description - Startup Plan - $600" pack={23}>
                                Product Description - Startup Plan - $600</option>
                              <option value="Product Description - Scaling Plan - $450" pack={24}>
                                Product Description - Scaling Plan - $450</option>
                              <option value="Product Description - Scaling Plan - $900" pack={25}>
                                Product Description - Scaling Plan - $900</option>
                              <option value="Product Description - Venture Plan - $600" pack={26}>
                                Product Description - Venture Plan - $600</option>
                              <option value="Product Description - Venture Plan - $1200" pack={27}>Product Description - Venture Plan - $1200</option>
                            </optgroup>
                            <optgroup>
                              <option disabled value pack={0} className="colr">Long Form
                                Articles Package</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Long Form Articles - Startup Plan - $1999/m" pack={28}>Long Form Articles - Startup Plan - $1999/m</option>
                              <option value="Long Form Articles - Scaling Plan - $3450/m" pack={29}>Long Form Articles - Scaling Plan - $3450/m</option>
                              <option value="Long Form Articles - Venture Plan - $4999/m" pack={30}>Long Form Articles - Venture Plan - $4999/m</option>
                            </optgroup>
                            <optgroup>
                              <option disabled value pack={0} className="colr">Website Content
                                Package</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Web Content - Startup Plan - $500/m" pack={10}>Web
                                Content - Kick Starter Package - $199/m</option>
                              <option value="Web Content - Scaling Plan - $399/m" pack={11}>Web
                                Content - Scaling Package - $399/m</option>
                              <option value="Web Content - Venture Plan - $699/m" pack={12}>Web
                                Content - Venture Plan - $699/m</option>
                            </optgroup>
                            <optgroup className="pack_select" label="    --------------------------------------------">
                              <option value="Combo Package - $1,200.00" pack={10}>Combo Package -
                                $1,200.00</option>
                            </optgroup>
                          </select>
                        </div>
                        <div className="col-md-12 col-xs-12 margin-bottom-20 clearfix">
                          <textarea className="form-control" name="message" placeholder="*Business Description" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Business Description'" required="required" defaultValue={""} />
                          <p className="pull-left">Please tell us briefly about your business or the
                            services you provide</p>
                        </div>
                        <input type="hidden" name="pkg_key" />
                        <input type="hidden" name="form_name" className="popup_form" defaultValue="popup form" />
                        <input type="hidden" name=" lead_source " defaultValue="webcraftsolutions.com/" />
                        <input type="hidden" name="brand" defaultValue="webcraftsolutions.com" />
                        <span className="form-extra-data" />
                        <div className="text-center mdl-btnn">
                          {/*<a class="btn-fill btn-quote btn-step-next" style="cursor:pointer;" id="package_submit" >Submit</a>*/}
                          <button type="submit" className="btn-quote btn-step-next" style={{ cursor: 'pointer', border: 0 }}>Submit</button>
                        </div>
                      </div></section>
                  </div>
                </form></div>
            </div>
          </section></div>
      </div>

      <div id="roiModal" className="modal roimdll">
        <div className="modal-content">
          <span className="close close_modal">×</span>
          <div className="container">
            <div className="model-title">
              <h4>Web Craft Solutions for Startup, Midsize and Enterprise Companies</h4>
              <p>Audit Your Site - More Sales, More Leads, More Happy Customers...</p>
            </div>
            <div className="model-bdy">
              <form method="POST" action="javascript:;" className="form-get-quote">
                <div className="row">
                  <div className="col-md-6">
                    <input type="text" name="name" required className="form-control" placeholder="Enter Your Name" />
                  </div>
                  <div className="col-md-6">
                    <input type="text" name="email" required className="form-control" placeholder="Enter Email Address" />
                  </div>
                  <div className="col-md-12">
                    <input type="text" name="phone" required className="form-control" placeholder="Enter Phone Number" />
                  </div>
                  <div className="col-md-12">
                    <textarea required name="message" placeholder="Enter Message Here" defaultValue={""} />
                  </div>
                  <input type="hidden" name="pkg_key" />
                  <input type="hidden" name="form_name" className="popup_form" defaultValue="popup form" />
                  <input type="hidden" name=" lead_source " defaultValue="webcraftsolutions.com/" />
                  <input type="hidden" name="brand" defaultValue="webcraftsolutions.com" />
                  <span className="form-extra-data" />
                  <div className="col-md-12 roi-bntt">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}