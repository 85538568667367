import Slider from "react-slick";

export default function Testimonial(){

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

     return (

      <section className="testimonial-section slider-test orange-gradient all-section">
        <div className="container">
          <div className="test-ss">
          <Slider {...settings}>

            
            <div className="test-box">
              <div className="row align-items-center">
                <div className="col-md-5 col-sm-8">
                  <div className="review-text" data-aos="fade-right" data-aos-duration={1000}>
                    <div className="top-head">
                      <span className="head-upper" />
                      <span className="head-after" />
                    </div>
                    <h3 className="section-head">What Our Clients Say</h3>
                    <p>Nice work, I appreciate the fact that you delivered the project early and with all
                      the requirements fulfilled. My website looks stunning and the design is customized
                      for my brand.</p>
                    <p>Carl Alamode</p>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3">
                  <div className="test-user" data-aos="flip-left" data-aos-duration={1000}>
                    <img src="images/test-img4.jpg" alt="Image" className="img-responsive" />
                  </div>
                </div>
                <div className="col-md-5">
                  <figure className="web-img" data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/wesite-img.png" alt="Image" className="img-responsive" />
                  </figure>
                </div>
              </div>
            </div>

            <div className="test-box">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="review-text" data-aos="fade-right" data-aos-duration={1000}>
                    <div className="top-head">
                      <span className="head-upper" />
                      <span className="head-after" />
                    </div>
                    <h3 className="section-head">That’s what our Clients Says</h3>
                    <p>You have done a great job optimizing my website. It is loading a lot faster and
                      ranking much higher than before. I am also getting consistent new visitors.</p>
                    <p>Anabel James
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="test-user" data-aos="flip-left" data-aos-duration={1000}>
                    <img src="images/test-img3.jpg" alt="Image" className="img-responsive" />
                  </div>
                </div>
                <div className="col-md-5">
                  <figure className="web-img" data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/wesite-img2.png" alt="Image" className="img-responsive" />
                  </figure>
                </div>
              </div>
            </div>

            <div className="test-box">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="review-text" data-aos="fade-right" data-aos-duration={1000}>
                    <div className="top-head">
                      <span className="head-upper" />
                      <span className="head-after" />
                    </div>
                    <h3 className="section-head">That’s what our Clients Says</h3>
                    <p>You have fulfilled every requirement for my eCommerce website within record time. I
                      am glad that I hired you guys for developing my online store.</p>
                    <p>Julia Constantine
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="test-user" data-aos="flip-left" data-aos-duration={1000}>
                    <img src="images/test-img2.jpg" alt="Image" className="img-responsive" />
                  </div>
                </div>
                <div className="col-md-5">
                  <figure className="web-img" data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/wesite-img3.png" alt="Image" className="img-responsive" />
                  </figure>
                </div>
              </div>
            </div>
            
            </Slider>
          </div>
        </div>
      </section>

    //   <div>
    //   <h2> Single Item</h2>
    //   <Slider {...settings}>
    //     <div>
    //       <h3>1</h3>
    //     </div>
    //     <div>
    //       <h3>2</h3>
    //     </div>
    //     <div>
    //       <h3>3</h3>
    //     </div>
    //     <div>
    //       <h3>4</h3>
    //     </div>
    //     <div>
    //       <h3>5</h3>
    //     </div>
    //     <div>
    //       <h3>6</h3>
    //     </div>
    //   </Slider>
    // </div>
      
    );
}