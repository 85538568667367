

export default function Aim(){
     return (

      <section className="company-section all-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-top text-center" data-aos="fade-down" data-aos-duration={1000}>
                <div className="top-head mx-auto">
                  <span className="head-before" />
                  <span className="head-upper" />
                  <span className="head-after" />
                </div>
                <h3 className="section-head">Aim To The Top Of Your Industry!</h3>
                <p>A custom website is vital for your business growth and we will make sure that your business
                  is at the top of your industry. We can develop a project for any industry in record time.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-top">
            <div className="col-sm-4 ">
              <ul className="theme-list pink-list" data-aos="fade-up" data-aos-delay={100} data-aos-offset={0} data-aos-duration={1000}>
                <li>
                  <p>Food</p>
                </li>
                <li>
                  <p>Beauty</p>
                </li>
                <li>
                  <p>Education</p>
                </li>
                <li>
                  <p>Healthcare</p>
                </li>
                <li>
                  <p>Automobile</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 ">
              <ul className="theme-list purple-list" data-aos="fade-up" data-aos-delay={300} data-aos-offset={0} data-aos-duration={1000}>
                <li>
                  <p>E-commerce</p>
                </li>
                <li>
                  <p>Entertainment</p>
                </li>
                <li>
                  <p>Medical &amp; Drug</p>
                </li>
                <li>
                  <p>Business &amp; Consulting</p>
                </li>
                <li>
                  <p>Construction</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 ">
              <ul className="theme-list pink-list" data-aos="fade-up" data-aos-delay={600} data-aos-offset={0} data-aos-duration={1000}>
                <li>
                  <p>Financial</p>
                </li>
                <li>
                  <p>Clothing &amp; Garments</p>
                </li>
                <li>
                  <p>Hotels &amp; Restaurants</p>
                </li>
                <li>
                  <p>Film &amp; Studio</p>
                </li>
                <li>
                  <p>Law</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
    );
}