import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';




export default function WebsiteDevelopment(){

  setTimeout(() => {
    document.getElementById('websiteDesign').click();
    document.getElementById('nav-web-tab').click()
  }, 1000);

  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Website Development';
},[]);

	return(
    <> 
    <Header />
    
    <section id="content-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>Custom Web Development For The New Age Of Businesses</h1>
                <p className="text-white">Web Craft Solutions is here to take your business beyond nations to around
                  the world. Our custom website design and development will make sure that your business
                  website is within reach of the entire world. We will make sure that your website attracts
                  anyone who is in need of your products or services.</p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              < All_BannerForm />
            </div>
          </div>
        </div>
    </section>


     <Packages/>
     <Portfolio/>

     <section className="logo-rotate timeline-section all-section">
        <div className="container">
          <div className="col-md-10 mx-auto">
            <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
              <div className="top-head mx-auto">
                <span className="head-before" />
                <span className="head-upper" />
                <span className="head-after" />
              </div>
              <h3 className="section-head">Benefits Of Our Custom Website Development</h3>
            </div>
          </div>
          <div className="timeline-middlepath">
            <div className="row">
              <div className="col-md-2 mx-auto">
                <div className="timeline-middle">
                  <img src="images/timeline-rocket.png" alt="Image" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
          <div className="time-box">
            <div className="row order-change">
              <div className="col-md-5 col-sm-6 col-9">
                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                  <span>01</span>
                  <span className="box-head text-uppercase">Automated Platform </span>
                  <p>Your website will be a fully automated and 24/7 working sales machine. Your business will
                    keep on running through your website even when you are asleep. </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/timeline1.jpg" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
          </div>
          <div className="time-box  right-time">
            <div className="row ">
              <div className="col-md-5 col-sm-6 col-3">
                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                  <img src="images/timeline2.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
              <div className="col-md-5 col-sm-6  col-9 offset-md-2">
                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                  <span>02</span>
                  <span className="box-head text-uppercase">International Reach</span>
                  <p>Through the website, your business will be able to reach and attract customers that are
                    thousands of miles away from you and sell them your product with ease. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="time-box">
            <div className="row order-change">
              <div className="col-md-5 col-sm-6 col-9">
                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                  <span>03</span>
                  <span className="box-head text-uppercase">Increased Engagement</span>
                  <p>
                    Our design will be specialized to engage with your visitors and encourage them to try
                    your products. Your website will make your customers feel smart for buying from you.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/timeline3.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
          </div>
          <div className="time-box  right-time">
            <div className="row ">
              <div className="col-md-5 col-sm-6 col-3">
                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                  <img src="images/timeline4.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
              <div className="col-md-5 col-sm-6 col-9 offset-md-2">
                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                  <span>04</span>
                  <span className="box-head text-uppercase">Increased Conversions </span>
                  <p>We will design your website with a strategic flow that will guide your visitors to the
                    products or services they need and then towards the checkout button soon after.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Contact/>

    <Footer />
    </>

		)
}