

export default function Process(){
    return (

      <div className="grey-bg about-help">
        <div className="help-out">
          <section className="help-section  all-section">
            <div className="container">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
                    <div className="top-head mx-auto">
                      <span className="head-before" />
                      <span className="head-upper" />
                      <span className="head-after" />
                    </div>
                    <h3 className="section-head">Our Process</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 d-flex d-md-block col-sm-12">
                  <div className="help-box" data-aos="fade-right" data-aos-duration={1000}>
                    <figure className="d-flex align-items-end justify-content-between">
                      <img src="images/help1.png" alt="Image" className="img-responsive" />
                      <span className="float-right">01</span>
                    </figure>
                    <span className="box-head">Project Consultation</span>
                    <p>We start with a detailed conversation regarding the project and determine all the
                      requirements or goals of the projects that must be achieved.</p>
                  </div>
                  <div className="help-box" data-aos="fade-right" data-aos-duration={1000}>
                    <figure className="d-flex align-items-end justify-content-between">
                      <img src="images/help3.png" alt="Image" className="img-responsive" />
                      <span className="float-right">03</span>
                    </figure>
                    <span className="box-head"> Project Designing</span>
                    <p>Up next, we develop a strategy for the project and design it accordingly. The design
                      is later sent to the client for approval and suggestions.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <figure className="help-rocket">
                    <img src="images/help-rocket.png" data-aos="fade-up" data-aos-duration={1000} alt="Image" className="rocket img-responsive" />
                    <span className="help-icons">
                      <div className="shape-1">
                        <img alt="Image" className="img-fluid" src="images/help-icon1.png" />
                      </div>
                      <div className="shape-2">
                        <img alt="Image" className="img-fluid" src="images/help-icon2.png" />
                      </div>
                      <div className="shape-3">
                        <img alt="Image" className="img-fluid" src="images/help-icon3.png" />
                      </div>
                    </span>
                  </figure>
                </div>
                <div className="col-md-3 d-flex d-md-block">
                  <div className="help-box" data-aos="fade-left" data-aos-duration={1000}>
                    <figure className="d-flex align-items-end justify-content-between">
                      <img src="images/help2.png" alt="Image" className="img-responsive" />
                      <span className="float-right">02</span>
                    </figure>
                    <span className="box-head">Project Development</span>
                    <p>Once the design is approved, we start coding the website. As the development is
                      finished, we aggressively test to project for any issues.</p>
                  </div>
                  <div className="help-box" data-aos="fade-left" data-aos-duration={1000}>
                    <figure className="d-flex align-items-end justify-content-between">
                      <img src="images/help4.png" alt="Image" className="img-responsive" />
                      <span className="float-right">04</span>
                    </figure>
                    <span className="box-head">Project Launch</span>
                    <p>Once all the issues are fixed the project is sent for the final approval of the
                      clients. If changes are needed, we add them and make the project live.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
        </div>
      
      </div>
      
    );
}