import Header from
    '../Components/Header';
import Footer from
    '../Components/Footer';
import Contact from
    '../Components/Contact';





export default function TermsCondition() {

    return (
        <>
            <Header />

            <section className="privacy-section all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="section-top text-center" data-aos="fade-down" data-aos-duration={1000}>
                                <div className="top-head mx-auto">
                                    <span className="head-before" />
                                    <span className="head-upper" />
                                    <span className="head-after" />
                                </div>
                                <h3 className="section-head">Terms &amp; Conditions</h3>
                                <p>By accessing our website “Web Craft Solutions”, you are agreeing to be bound by the following
                                    Terms and Conditions of our website users. If you do not agree with any of the mentioned
                                    terms, you are advised to close this site. The content shared on this website is protected:
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="privacy-content">
                        <span className="section-head">Access to Information</span>
                        <p>Permission is granted to temporarily download one copy of the materials (information or information)
                            on Web Craft Solutions website for personal, non-commercial transitory viewing only. This is the
                            grant of a license, not a transfer of title, and under this license, you may not:</p>
                        <ul>
                            <li>
                                <p>Modify or copy the materials;</p>
                            </li>
                            <li>
                                <p>Use the materials for any commercial purpose, or any public display (commercial or
                                    non-commercial);</p>
                            </li>
                            <li>
                                <p>Attempt to decompile or reverse engineer any information contained on Web Craft Solutions
                                    web site;</p>
                            </li>
                            <li>
                                <p>Remove any copyright or other proprietary notations from the materials; or</p>
                            </li>
                            <li>
                                <p>Transfer the materials to another person or "mirror" the materials on any other server.
                                </p>
                            </li>
                        </ul>
                        <p>This license shall automatically terminate if you violate any of these restrictions and may be
                            terminated by Web Craft Solutions at any time. Upon terminating your viewing of these materials or
                            upon the termination of this license, you must destroy any downloaded materials in your possession
                            whether in electronic or printed format.</p>
                        <span className="section-head">Disclaimer</span>
                        <p>The materials on Web Craft Solutions website are provided "as is". Web Craft Solutions makes no
                            warranties, expressed or implied, and hereby disclaims and negates all other warranties, including
                            without limitation, implied warranties or conditions of merchantability, fitness for a particular
                            purpose, or non-infringement of intellectual property or other violation of rights. Further,
                            Web Craft Solutions does not warrant or make any representations concerning the accuracy, likely results, or
                            reliability of the use of the materials on its Internet website or otherwise relating to such
                            materials or on any sites linked to this site.</p>
                        <span className="section-head">Limitations</span>
                        <p>In no event shall Web Craft Solutions or its suppliers be liable for any damages (including, without
                            limitation, damages for loss of data or profit, or due to business interruption,) arising out of the
                            use or inability to use the materials on Web Craft Solutions Internet site, even if a Web Craft Solutions authorized representative has been notified orally or in writing of the
                            possibility of such damage. Because some jurisdictions do not allow limitations on implied
                            warranties, or limitations of liability for consequential or incidental damages, these limitations
                            may not apply to you. The materials appearing on Web Craft Solutions website could include
                            technical, typographical, or photographic errors. Web Craft Solutions does not warrant that any of
                            the materials on its website are accurate, complete, or current. Web Craft Solutions may make changes
                            to the materials contained on its website at any time without notice. Web Craft Solutions does not,
                            however, make any commitment to update the materials.</p>
                        <span className="section-head">Links</span>
                        <p>Web Craft Solutions has not reviewed all of the sites linked to its Internet website and is not
                            responsible for the contents of any such linked site. The inclusion of any link does not imply
                            endorsement by the Web Craft Solutions of the site. Use of any such linked website is at the user's
                            own risk.</p>
                        <span className="section-head">Website Modifications</span>
                        <p>Web Craft Solutions may revise these terms of use for its website at any time without notice. By using
                            our website, you are agreeing to be bound by the then current version of these Terms and Conditions
                            of Use.</p>
                    </div>
                </div>
            </section>



            <Contact />

            <Footer />
        </>

    )
}