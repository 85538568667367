import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';




export default function LogoDesign(){

  setTimeout(() => {
    document.getElementById('logoDesign').click();
    document.getElementById('nav-logo-tab').click()
  }, 1000);
  
  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Logo Design';

},[]);

	return(
    <> 
    <Header />
    
    <section id="social-banner" className="services-banner inner-banner orange-gradient logobanner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>Custom Logo Designs Unique & Interactive</h1>
                <p className="text-white">An exceptional team of award-winning designers is waiting to craft an
                  immaculate logo design that speaks on your brand's behalf. Strong brand identity will enable
                  you to achieve long-term growth. Perks of using our logo design services </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              < All_BannerForm />
            </div>
          </div>
        </div>
    </section>


     <Packages/>
     <Portfolio/>
     <Contact/>

    <Footer />
    </>

		)
}