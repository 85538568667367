import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import ContactComp from 
'../Components/Contact';
import { useEffect } from 'react';

export default function Contact(){

  useEffect(() => {
    document.title = 'Web Craft Solutions | Contact Us';
}, []);

	return(
    <> 
    <Header />
    
        <section id="contact-banner" className="inner-banner inner-bannercontact">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <div className="inner-banner-text" data-aos="fade-right" data-aos-duration={1000}>
                  <h1><span>Contact Us</span>Get in Touch</h1>
                  <p className="text-white">Hey, we are looking forward to working with you on your most preferred
                    digital branding solution. Enter your details and we will get back to your shortly.</p>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="inner-banner-img" data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/contact-img.png" alt="Image" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-info-section all-section">
          <div className="container">
            <div className="map-part">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.970002996433!2d-74.03707238475981!3d40.718676845144344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c257ed77359f7d%3A0x10a99934867d9e04!2sRegus%20-%20Jersey%20City%20-%20Harborside%20Financial!5e0!3m2!1sen!2s!4v1662685221777!5m2!1sen!2s" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3">
                <div className="contact-info text-center">
                  <span className="contact-icon" data-aos="zoom-in" data-aos-duration={1000}>
                    <i className="fa fa-map-marker" aria-hidden="true" />
                  </span>
                  <div data-aos="fade-up" data-aos-duration={1000}>
                    <h3>Address</h3>
                    <p>ABC Manhattan, 5th Floor, New York City, NY, 10013, USA</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="contact-info text-center">
                  <span className="contact-icon" data-aos="zoom-in" data-aos-duration={1000}>
                    <i className="fa-solid fa-envelope" />
                  </span>
                  <div data-aos="fade-up" data-aos-duration={1000}>
                    <h3>Mail</h3>
                    <a href="mailto:info@webcraftsolutions.com">info@webcraftsolutions.com</a>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-3">
                <div className="contact-info text-center">
                  <span className="contact-icon" data-aos="zoom-in" data-aos-duration={1000}>
                    <i className="fa-solid fa-clock" />
                  </span>
                  <div data-aos="fade-up" data-aos-duration={1000}>
                    <h3>Office Hours</h3>
                    <p>Week Days: 10:00 – 22:00 <br />
                      Sunday: Close</p>
                  </div>
                </div>
              </div> */}
              <div className="col-sm-3">
                <div className="contact-info text-center">
                  <span className="contact-icon" data-aos="zoom-in" data-aos-duration={1000}>
                    <i className="fa fa-headphones" aria-hidden="true" />
                  </span>
                  <div data-aos="fade-up" data-aos-duration={1000}>
                    <h3>Phone</h3>
                    <a href="tel:+1234567891">1234567891</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    <ContactComp />
    <Footer />
    </>

		)
}