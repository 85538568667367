import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';



export default function Ecommerce(){

  setTimeout(() => {
    document.getElementById('eCommerce').click();
    document.getElementById('nav-ecommerce-tab').click()
  }, 1000);

  useEffect(() => {


    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Ecommerce';
},[]);

	return(
    <> 
    <Header />
    
    <section id="seo-banner" className="services-banner inner-banner orange-gradient ecommercebanner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>eCommerce Website Development For Modern Businesses</h1>
                <p className="text-white">
                  Let us help you take your business from the old and expensive physical stores to the online
                  platform of unlimited potential. We can design and develop a customized eCommerce store for
                  your brand. The platform will show the uniqueness and individuality of your brand.</p>
                <p className="text-white">
                  We will integrate all the must needed marketing tools and features that are required for
                  conducting automated business. Secure payment processing
                  and follow up marketing are just to name a few.
                </p>
                <p className="text-white">We have been developing eCommerce websites for more than 10 years and you
                  will surely receive the benefit of our extensive experience.</p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              <form className="banner-form four-inputs form-get-quote" method="POST" action="javascript:;">
                <input type="text" placeholder="Enter Name Here" name="full_name" required />
                <input type="email" placeholder="Enter Email Here" name="email" required />
                <input type="text" className="phoneNum" placeholder="Enter Phone Number" name="phone" required />
                <input type="text" placeholder="Message" name="message" required />
                <input type="hidden" name="pkg_key" />
                <input type="hidden" name="form_name" className="popup_form" defaultValue="popup form" />
                <input type="hidden" name=" lead_source " defaultValue="webcraftsolutions.com/ecommerce.html" />
                <input type="hidden" name="brand" defaultValue="webcraftsolutions.com" />
                <span className="form-extra-data" />
                <button className="orange-gradient theme-btn" type="submit">Get a Quote</button>
              </form>
            </div>
          </div>
        </div>
    </section>

     <Packages/>
     <Portfolio/>

     <section className="logo-rotate timeline-section all-section">
        <div className="container">
          <div className="col-md-10 mx-auto">
            <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
              <div className="top-head mx-auto">
                <span className="head-before" />
                <span className="head-upper" />
                <span className="head-after" />
              </div>
              <h3 className="section-head">Benefits Of Our Custom Website Development</h3>
            </div>
          </div>
          <div className="timeline-middlepath">
            <div className="row">
              <div className="col-md-2 mx-auto">
                <div className="timeline-middle">
                  <img src="images/timeline-rocket.png" alt="Image" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
          <div className="time-box">
            <div className="row order-change">
              <div className="col-md-5 col-sm-6 col-9">
                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                  <span>01</span>
                  <span className="box-head text-uppercase">Automated Platform </span>
                  <p>Your website will be a fully automated and 24/7 working sales machine. Your business will
                    keep on running through your website even when you are asleep. </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/timeline1.jpg" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
          </div>
          <div className="time-box  right-time">
            <div className="row ">
              <div className="col-md-5 col-sm-6 col-3">
                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                  <img src="images/timeline2.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
              <div className="col-md-5 col-sm-6  col-9 offset-md-2">
                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                  <span>02</span>
                  <span className="box-head text-uppercase">International Reach</span>
                  <p>Through the website, your business will be able to reach and attract customers that are
                    thousands of miles away from you and sell them your product with ease. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="time-box">
            <div className="row order-change">
              <div className="col-md-5 col-sm-6 col-9">
                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                  <span>03</span>
                  <span className="box-head text-uppercase">Increased Engagement</span>
                  <p>
                    Our design will be specialized to engage with your visitors and encourage them to try
                    your products. Your website will make your customers feel smart for buying from you.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/timeline3.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
          </div>
          <div className="time-box  right-time">
            <div className="row ">
              <div className="col-md-5 col-sm-6 col-3">
                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                  <img src="images/timeline4.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
              <div className="col-md-5 col-sm-6 col-9 offset-md-2">
                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                  <span>04</span>
                  <span className="box-head text-uppercase">Increased Conversions </span>
                  <p>We will design your website with a strategic flow that will guide your visitors to the
                    products or services they need and then towards the checkout button soon after.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Contact/>

    <Footer />
    </>

		)
}