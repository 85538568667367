
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export default function All_BannerForm() {

  // Sendmail floatForm
  const allBannerForm = useRef();
  const all_bannerForm = (e) => {
  e.preventDefault();

    emailjs.sendForm('service_8iuyr9c', 'template_3s65mss', allBannerForm.current, 'mFs_PVy5BMJMtOR91')
      .then((result) => {
          alert('Submit Successfully.');
          document.getElementById("allBannerForm").reset();
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  return (

    <div className="row">
      <div className="col-lg-12 mx-auto">
        <form className="banner-form three-input form-get-quote" id="allBannerForm" ref={allBannerForm} onSubmit={all_bannerForm}>
          <input type="text" placeholder="Enter Name Here"  name="user_name" required />
          <input type="email" placeholder="Enter Email Here" name="user_email" required />
          <input type="text" className="phoneNum" placeholder="Enter Phone Number" name="user_phone" required />
          <input type="text" placeholder="Message" name="message" required />
          <button className="orange-gradient theme-btn" type="submit">Get a Quote</button>
        </form>
      </div>
    </div>

  );
}