import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Process from 
'../Components/Process';
import ComboPackages from 
'../Components/ComboPackages';
import Portfolio from 
'../Components/Portfolio';
import Professional from 
'../Components/Professional';
import Support from 
'../Components/Support';
import Contact from 
'../Components/Contact';
import All_BannerForm from '../Components/All_BannerForm';




export default function Home(){
  
	return(
   <>
    <Header/>
    
        <section className="main_banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="banner-text" data-aos="fade-down" data-aos-duration={1000}>
                  <h2>Equip Your Business</h2>
                  <p>Web craft Solutions: the nexus of online creativity and digital functionality. As a leading website design agency, we elevate online portfolios for start-ups, medium-sized businesses, and established enterprises alike. Immerse your digital journey with the expertise of seasoned professionals fusing innovation and cutting-edge technology, transforming your vision into a vibrant reality.</p>
                  <ul>
                    <li>
                      <p>Customized Design</p>
                    </li>
                    <li>
                      <p>Light Weight Development</p>
                    </li>
                    <li>
                      <p>360 Degree Automation</p>
                    </li>
                    <li>
                      <p>24/7 Sales Machine</p>
                    </li>
                  </ul>
                  <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="img-responsive" alt="Banner" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="banner-img">
                  <img src="images/banner-laptop.png" className="img-responsive" alt="Banner" />
                  <span className="small-screen" data-aos="fade-left" data-aos-duration={1000}>
                    <img src="images/banner-small-screen.png" className="img-responsive" alt="Banner" />
                  </span>
                </div>
              </div>
            </div>
            <All_BannerForm />
            <span className="banner-side">
              <img src="images/banner-side.png" className="img-responsive" alt="Banner" />
            </span>
          </div>
        </section>

        <section className="box-section dark-gradient">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 p-0">
                <div className="box-content d-md-flex d-block align-items-center bd-left">
                  <figure className="w-25" data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/box-img1.png" alt="Image" className="img-responsive" />
                  </figure>
                  <div className="box-text w-75" data-aos="fade-left" data-aos-duration={1000}>
                    <span>1800+</span>
                    <p className="text-white">Happy Customers Worldwide</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 p-0">
                <div className="box-content d-md-flex d-block align-items-center">
                  <figure className="w-25" data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/box-img2.png" alt="Image" className="img-responsive" />
                  </figure>
                  <div className="box-text w-75" data-aos="fade-left" data-aos-duration={1000}>
                    <span>Affordable</span>
                    <p className="text-white">Digital Branding Packages</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 p-0">
                <div className="box-content d-md-flex d-block align-items-center">
                  <figure className="w-25" data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/box-img3.png" alt="Image" className="img-responsive" />
                  </figure>
                  <div className="box-text w-75" data-aos="fade-left" data-aos-duration={1000}>
                    <span>Experts</span>
                    <p className="text-white">Industry-Vetted Services</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="choose-our">
          <div className="container">
            <div className="choose-title">
              <h4>Choose Unlimited Potential At Least Expenses</h4>
              <p>A website may be cheaper than stores or offices but it has a compounding growth factor. When handled
                by experts like us a customized website can produce compounding ROI.</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="choose-blk" data-aos="fade-up" data-aos-duration={1000}>
                  <img src="images/More-Traffic.png" />
                  <div className="choose-txt">
                    <h4>Customized Design</h4>
                    <p>Our customized and unique design will encourage visitors to explore your website and
                      spend more time browsing through your products or services.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose-blk" data-aos="fade-up" data-aos-duration={1000}>
                  <img src="images/More-Leads.png" />
                  <div className="choose-txt">
                    <h4>Brand Identity</h4>
                    <p>We will design your website in contrast to your brand identity. Our design and
                      development will reflect your brand on your customized website.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose-blk" data-aos="fade-up" data-aos-duration={1000}>
                  <img src="images/More-Revenue.png" />
                  <div className="choose-txt">
                    <h4>Targeted Traffic</h4>
                    <p>Your website will attract the right people to your brand. We will design a website that
                      will be most effective in targeting the right audience for you.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose-blk" data-aos="fade-up" data-aos-duration={1000}>
                  <img src="images/More-Brand-Awareness.png" />
                  <div className="choose-txt">
                    <h4>Brand Recognition</h4>
                    <p>The logo and website we will design for your business will help it become a well-known
                      brand in your industry in no time at all. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose-blk" data-aos="fade-up" data-aos-duration={1000}>
                  <img src="images/More-Business-Growth.png" />
                  <div className="choose-txt">
                    <h4>Trust Value</h4>
                    <p>If your business is easy to recognize and hard to forget. This will ensure that your
                      business is trustworthy and people will think of you first.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="choose-blk" data-aos="fade-up" data-aos-duration={1000}>
                  <img src="images/More-Trust-and-Authority.png" />
                  <div className="choose-txt">
                    <h4>Global Reach</h4>
                    <p>Our custom website will ensure that your business reach is beyond your own country.
                      People from all over the world will demand your products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Process/>

        {/* <ComboPackages/> */}

        <Portfolio/>

        <Professional/>

        <Support/>

        <section className="best-section all-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="best-text" data-aos="fade-right" data-aos-duration={1000}>
                <div className="top-head">
                  <span className="head-upper" />
                  <span className="head-after" />
                </div>
                <h3 className="section-head">Get Success With Web craft Solutions</h3>
                <p>Web Craft Solutions is a custom web development agency that is known for delivering results.
                  We can ensure that the goals of your business are achieved through our data driven
                  strategies that are proven to produce results. We have been developing websites for more
                  than 10 years and we have worked with thousands of businesses, helping them to reach the
                  heights they aimed for.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <figure data-aos="zoom-in" data-aos-duration={1000}>
                <img src="images/best-img.jpg" alt="Image" className="img-responsive" />
              </figure>
            </div>
          </div>
        </div>
        </section>

        <Contact/>



    <Footer/>
   </>
 
		)
}