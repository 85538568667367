import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';


export default function ContentMartketing(){

  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Content Writting';
}
,[]);

	return(
    <> 
    <Header />
    
    <section id="content-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>Content Writting</h1>
                <p className="text-white">Web Craft Solutions takes pride in tailoring spectacular content for our
                  clients. We aim to work together with you and build together with important principles such
                  as trust, integrity, respect, and determination. Our expert team of creatives is focused on
                  creating excellent material for your brand that defines your identity and grows your
                  business. When you choose Web Craft Solutions as your content marketing strategist, you choose
                  excellence. </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              <All_BannerForm/>
            </div>
          </div>
        </div>
      </section>

     <Packages/>

     <section className="logo-rotate timeline-section all-section">
        <div className="container">
          <div className="col-md-10 mx-auto">
            <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
              <div className="top-head mx-auto">
                <span className="head-before" />
                <span className="head-upper" />
                <span className="head-after" />
              </div>
              <h3 className="section-head">We work on a simple principle for our content creation</h3>
              <p>We create sustainable and result-producing solutions for all your requirements and needs.
              </p>
            </div>
          </div>
          <div className="timeline-middlepath">
            <div className="row">
              <div className="col-md-2 mx-auto">
                <div className="timeline-middle">
                  <img src="images/timeline-rocket.png" alt="Image" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
          <div className="time-box">
            <div className="row order-change">
              <div className="col-md-5 col-sm-6 col-9">
                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                  <span>01</span>
                  <span className="box-head text-uppercase">Targeted content strategy </span>
                  <p>As SEO experts, we know how to create targeted content for businesses that drives
                    more people towards your brand and turns them into loyal customers. </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/timeline1.jpg" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
          </div>
          <div className="time-box  right-time">
            <div className="row ">
              <div className="col-md-5 col-sm-6 col-3">
                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                  <img src="images/timeline2.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
              <div className="col-md-5 col-sm-6  col-9 offset-md-2">
                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                  <span>02</span>
                  <span className="box-head text-uppercase">Industry Relevant Work</span>
                  <p>We have a highly creative team on board that has great experience in the industry and
                    uses their skills to develop industry-relevant content for you. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="time-box">
            <div className="row order-change">
              <div className="col-md-5 col-sm-6 col-9">
                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                  <span>03</span>
                  <span className="box-head text-uppercase">SEO friendly methods </span>
                  <p>SEO is a great tool to market your products efficiently and get the traffic and leads
                    you desire. Our team is skilled in creating SEO-friendly content that will do
                    wonders for your business. </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/timeline3.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
          </div>
          <div className="time-box  right-time">
            <div className="row ">
              <div className="col-md-5 col-sm-6 col-3">
                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                  <img src="images/timeline4.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
              <div className="col-md-5 col-sm-6 col-9 offset-md-2">
                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                  <span>04</span>
                  <span className="box-head text-uppercase">Innovative and Unique </span>
                  <p>We work to bring a fresh perspective to your work that helps you stand out among the
                    masses. With an innovative strategy and an attitude for success, anything is
                    possible. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Contact/>

    <Footer />
    </>

		)
}