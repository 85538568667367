

export default function Quote(){
    return (

      <div className="grey-bg">
        <div className="roi-top-part">
          <section className="roi-section logo-rotate3 ">
            <div className="container">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="roi-text text-center" data-aos="fade-left" data-aos-duration={1000}>
                    <h4>Looking For Reliable Services?</h4>
                    <h3>Get A Free Quote Now!</h3>
                    <div className="ex-btns text-center mt-5">
                      {/* <a class="dark-gradient theme-btn" href="contact.php" data-toggle="modal" data-target="#analyse-modal">Request Now</a> */}
                      {/* <a class="dark-gradient theme-btn" id="roiBtn" href="javascript:;">Request Now</a> */}
                      <a className="dark-gradient theme-btn" href="javascript:;" data-toggle="modal" data-target="#analyse-modal">Free Request Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="rotate-gsap3 roi-right">
              <img src="images/ranking-smg-bg.png" alt="Image" className="img-responsive" />
            </span>
          </section>
          <span className="roi-left">
            <img src="images/roi-left.png" alt="Image" className="img-responsive" />
          </span>
        </div>
      </div>

    );
}