


export default function Professional() {
     return (

      <div className="dark-gradient">
        <div className="content-out">
          <section className="custom-section ">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="custome-text" data-aos="fade-right" data-aos-duration={1000}>
                    <h3>In Need Of Professional And <br /> <strong>Effective Digital Services?</strong> <br />
                      <strong>Share Your Dreams</strong> With Us And Watch Them Realize.
                    </h3>
                    <a className="theme-btn orange-gradient" href="javascript:;" data-toggle="modal" data-target="#analyse-modal">Lets Grow Your Business</a>
                  </div>
                </div>
              </div>
            </div>
            <span className="blue-wave">
              <img src="images/box-top-img.png" alt="Image" className="img-responsive" />
            </span>
          </section>

          <div className="custom-img" data-aos="zoom-in" data-aos-duration={1000}>
            <img src="images/custom-img.png" alt="Image" className="img-responsive" />
          </div>
        </div>
      </div>
      
    );
}