
import React from 'react';
import ReactDOM from 'react-dom';
// import $ from 'jquery';
import { useEffect } from 'react';

export default function Portfolio() {

  useEffect(() => {
    window.$(".dataTarget").on("click", (function () {
      window.$(this).siblings().removeClass("current");
      window.$(this).addClass("current");
      var t = window.$(this).data("targetit");
      window.$("." + t).siblings('[class^="tabs"]').removeClass("current");
      window.$("." + t).addClass("current");
      window.$(".slick-slider").slick("setPosition", 0)
    }));

  }, []);



  return (
    <section className="portfoliowrp">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="txtwrp">
              <h4 className="upr-heading wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>
                Our Portfolio</h4>
              <h2 className="main-heading wow fadeInDown" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>Glimpses Of Recent Classics That We
                Have Created</h2>
              <p className="theportpara main-pera wow fadeInDown px-5 mx-5" style={{ visibility: 'visible', animationName: 'fadeInDown' }}>We
                build interactive websites that offer an amazing online experience and promise positive
                outcomes. Do not just go by our words, have a look at our recent project and decide by
                yourself. This portfolio perfectly describes our versatility in all market niches.</p>
            </div>
            <div className="navwrp">
              <ul className="tabbing-links">
                <li className="dataTarget" id='logoDesign' data-targetit="tabs-custm-web">Logo Design</li>
                <li className="dataTarget current" id='websiteDesign' data-targetit="tabs-web-design">Website Design</li>
                <li className="dataTarget" id='eCommerce' data-targetit="tabs-ecom">E-Commerce</li>
                <li className="dataTarget" id='wordPress' data-targetit="tabs-brand">Wordpress</li>
                <li className="dataTarget" id='motionGraphics' data-targetit="tabs-motion">Motion Graphics</li>
                <li className="dataTarget" id='shopify' data-targetit="tabs-illis">Shopify</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tabs ser-port tabs-custm-web wbport">
          <div className="row">
            <div className="col-md-12">
              <div className="port-box">
                <ul>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-1.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-1.png" data-src="images/portfolios/logo/t-1.png" alt="t-1" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-2.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-2.png" data-src="images/portfolios/logo/t-2.png" alt="t-2" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-3.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-3.png" data-src="images/portfolios/logo/t-3.png" alt="t-3" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-4.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-4.png" data-src="images/portfolios/logo/t-4.png" alt="t-4" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-5.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-5.png" data-src="images/portfolios/logo/t-5.png" alt="t-5" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-6.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-6.png" data-src="images/portfolios/logo/t-6.png" alt="t-6" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-7.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-7.png" data-src="images/portfolios/logo/t-7.png" alt="t-7" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-8.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/logo/t-8.png" data-src="images/portfolios/logo/t-8.png" alt="t-8" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-9.png">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/logo/t-9.png" alt="t-9" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-10.png">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/logo/t-10.png" alt="t-10" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-11.png">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/logo/t-11.png" alt="t-11" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/logo/t-12.png">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/logo/t-12.png" alt="t-12" />
                      </figure>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs ser-port tabs-brand wbport ">
          <div className="row">
            <div className="col-md-12">
              <div className="port-box">
                <ul>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/wordpress/1.jpg">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/wordpress/1.jpg" data-src="images/portfolios/wordpress/1.jpg" alt="t-1" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/wordpress/2.jpg">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/wordpress/2.jpg" data-src="images/portfolios/wordpress/2.jpg" alt="t-2" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/wordpress/3.jpg">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/wordpress/3.jpg" data-src="images/portfolios/wordpress/3.jpg" alt="t-3" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/wordpress/4.jpg">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/wordpress/4.jpg" data-src="images/portfolios/wordpress/4.jpg" alt="t-4" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/wordpress/5.jpg">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/wordpress/5.jpg" data-src="images/portfolios/wordpress/5.jpg" alt="t-5" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/wordpress/6.jpg">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/wordpress/6.jpg" data-src="images/portfolios/wordpress/6.jpg" alt="t-6" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs ser-port tabs-motion wbport">
          <div className="row">
            <div className="col-md-12">
              <div className="port-box">
                <ul>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-1.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-1.png" data-src="images/portfolios/motion-graphics/t-1.png" alt="t-1" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-2.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-2.png" data-src="images/portfolios/motion-graphics/t-2.png" alt="t-2" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-3.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-3.png" data-src="images/portfolios/motion-graphics/t-3.png" alt="t-3" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-4.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-4.png" data-src="images/portfolios/motion-graphics/t-4.png" alt="t-4" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-5.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-5.png" data-src="images/portfolios/motion-graphics/t-5.png" alt="t-5" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-6.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-6.png" data-src="images/portfolios/motion-graphics/t-6.png" alt="t-6" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-7.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-7.png" data-src="images/portfolios/motion-graphics/t-7.png" alt="t-7" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                  <li className>
                    <a data-fancybox="port" href="images/portfolios/motion-graphics/t-8.png">
                      <figure>
                        <img className="lazy loaded" src="images/portfolios/motion-graphics/t-8.png" data-src="images/portfolios/motion-graphics/t-8.png" alt="t-8" data-was-processed="true" />
                      </figure>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs ser-port tabs-web-design wbport current">
          <div className="row">
            <div className="col-md-12">
              <div className="port-box">
                <ul className="tabs imgport tabs-web-dsgn current wbport">
                  <a data-fancybox="port" href="images/t-8.jpg" alt="t-1">
                    <li className="hk_web_height lazy" data-src="images/t-8.jpg" alt="t-1" data-was-processed="true" style={{ backgroundImage: 'url(images/t-8.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-2.jpg" alt="t-2">
                    <li className="hk_web_height lazy" data-src="images/t-2.jpg" alt="t-2" data-was-processed="true" style={{ backgroundImage: 'url(images/t-2.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-3.jpg" alt="t-3">
                    <li className="hk_web_height lazy" data-src="images/t-3.jpg" alt="t-3" data-was-processed="true" style={{ backgroundImage: 'url(images/t-3.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-4.jpg" alt="t-4">
                    <li className="hk_web_height lazy" data-src="images/t-4.jpg" alt="t-4" data-was-processed="true" style={{ backgroundImage: 'url(images/t-4.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-5.jpg" alt="t-5">
                    <li className="hk_web_height lazy" data-src="images/t-5.jpg" alt="t-5" data-was-processed="true" style={{ backgroundImage: 'url(images/t-5.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-6.jpg" alt="t-6">
                    <li className="hk_web_height lazy" data-src="images/t-6.jpg" alt="t-6" data-was-processed="true" style={{ backgroundImage: 'url(images/t-6.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-7.jpg" alt="t-7">
                    <li className="hk_web_height lazy" data-src="images/t-7.jpg" alt="t-7" data-was-processed="true" style={{ backgroundImage: 'url(images/t-7.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/t-12.jpg" alt="t-8">
                    <li className="hk_web_height lazy" data-src="images/t-12.jpg" alt="t-8" data-was-processed="true" style={{ backgroundImage: 'url(images/t-12.jpg)' }}>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs ser-port tabs-ecom wbport">
          <div className="row">
            <div className="col-md-12">
              <div className="port-box">
                <ul className="tabs imgport tabs-web-dsgn current wbport">
                  <a data-fancybox="port" href="images/portfolios/ecommerce-design/t-1.jpg" alt="t-1">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-1.jpg" alt="t-1" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-1.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-2.jpg" alt="t-2">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-2.jpg" alt="t-2" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-2.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-3.jpg" alt="t-3">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-3.jpg" alt="t-3" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-3.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-4.jpg" alt="t-4">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-4.jpg" alt="t-4" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-4.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-5.jpg" alt="t-5">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-5.jpg" alt="t-5" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-5.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-6.jpg" alt="t-6">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-6.jpg" alt="t-6" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-6.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-7.jpg" alt="t-7">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-7.jpg" alt="t-7" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-7.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/t-8.jpg" alt="t-8">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-8.jpg" alt="t-8" data-was-processed="true" style={{ backgroundImage: 'url(images/portfolios/ecommerce/t-8.jpg)' }}>
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/9.jpg" alt="t-9">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-9.jpg" alt="t-9">
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/10.jpg" alt="t-10">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-10.jpg" alt="t-10">
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/11.jpg" alt="t-11">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-11.jpg" alt="t-11">
                    </li>
                  </a>
                  <a data-fancybox="port" href="images/portfolios/ecommerce/12.jpg" alt="t-12">
                    <li className="hk_web_height lazy" data-src="images/portfolios/ecommerce/t-12.jpg" alt="t-13">
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs ser-port tabs-illis wbport">
          <div className="row">
            <div className="col-md-12">
              <div className="port-box">
                <ul>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/1.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/1.jpg" alt="t-1" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/2.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/2.jpg" alt="t-2" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/3.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/4.jpg" alt="t-3" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/4.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/4.jpg" alt="t-4" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/5.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/5.jpg" alt="t-5" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/6.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/6.jpg" alt="t-6" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/7.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/7.jpg" alt="t-7" />
                      </figure>
                    </a>
                  </li>
                  <li className="hk_web_height lazy shopifyimg">
                    <a data-fancybox="port" href="images/portfolios/shopify/8.jpg">
                      <figure>
                        <img className="lazy" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="images/portfolios/shopify/8.jpg" alt="t-8" />
                      </figure>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}