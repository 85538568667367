import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import ContactCom from 
'../Components/Contact';
import { useEffect } from 'react';

export default function Contact(){

  useEffect(() => {
document.title = 'Web Craft Solutions | FAQ';
},[]);

	return(
    <> 
    <Header />
    
    <div className="faq-inner">
        <section className="faq-section all-section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="section-top text-center" data-aos="fade-down" data-aos-duration={1000}>
                  <div className="top-head mx-auto">
                    <span className="head-before" />
                    <span className="head-upper" />
                    <span className="head-after" />
                  </div>
                  <h3 className="section-head">Most Common FAQs </h3>
                  <p>Here you can read some of the most frequently asked questions that visitors ask us on a
                    regular basis.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7">
                <div id="accordion" className="my-5" data-aos="fade-right" data-aos-duration={1000}>
                  <div className="card  first-bd">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button className="btn btn-link text-left w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Why Do I Need A Custom Website Design?
                          <i className="fa fa-minus float-right" aria-hidden="true" />
                        </button>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div className="card-body">
                        You will need your website to look authentic and unique. A custom website design
                        will help your website gain trust, otherwise, your website will look copied and
                        fake. </div>
                    </div>
                  </div>
                  <div className="card  second-bd">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button className="btn btn-link text-left w-100 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How My Website Will Rank Higher On Google?
                          <i className="fa fa-minus float-right" aria-hidden="true" />
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div className="card-body">
                        Your website must be up to date with Google standards and it needs to be
                        optimized for your targeted keywords.
                      </div>
                    </div>
                  </div>
                  <div className="card  third-bd">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button className="btn btn-link text-left w-100 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          What Should Be The Cost Of A Custom Website?
                          <i className="fa fa-minus float-right" aria-hidden="true" />
                        </button>
                      </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                      <div className="card-body">
                        The cost of a project is subject and different for every project. It depends on
                        the complexity of your project and the functionality it needs.
                      </div>
                    </div>
                  </div>
                  <div className="card  forth-bd">
                    <div className="card-header" id="headingfour">
                      <h5 className="mb-0">
                        <button className="btn btn-link text-left w-100 collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                          How To Get More Visitors On The Website?
                          <i className="fa fa-minus float-right" aria-hidden="true" />
                        </button>
                      </h5>
                    </div>
                    <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                      <div className="card-body">
                        There are many ways to get more visitors to your website like SEO, Social Media
                        Marketing, and Paid Advertisements. </div>
                    </div>
                  </div>
                  <div className="card  fifth-bd">
                    <div className="card-header" id="headingfive">
                      <h5 className="mb-0">
                        <button className="btn btn-link text-left w-100 collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                          Can You Create An Ecommerce website?
                          <i className="fa fa-minus float-right" aria-hidden="true" />
                        </button>
                      </h5>
                    </div>
                    <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                      <div className="card-body">
                        Yes, we can, developing eCommerce websites is one of our main specialties.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
                <figure data-aos="zoom-in" data-aos-duration={1000}>
                  <img alt="Image" className="img-fluid" src="images/faq-img.png" />
                </figure>
              </div>
            </div>
          </div>
          <span className="faq-logo">
            <img alt="Image" className="img-fluid" src="images/faq-logo.png" />
          </span>
        </section>
    </div>

    <ContactCom />

    <Footer />
    </>

		)
}