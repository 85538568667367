import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';



export default function Wordpress(){

  setTimeout(() => {
    document.getElementById('wordPress').click();
    document.getElementById('nav-wordpress-tab').click()
  }, 1000);

  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Wordpress';
},[]);

	return(
    <> 
    <Header />
    
    <section id="local-seo-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center aos-init aos-animate" data-aos="fade-down" data-aos-duration={1000}>
                <h1>WordPress Development Services We Build Engaging Digital Experiences for Businesses</h1>
                <p className="text-white">As a top WordPress development firm, we assist businesses in making a name
                  for themselves in the digital market by creating inspiring WordPress websites that drive
                  traffic and produce higher revenue.
                </p>
                <p className="text-white">Local SEO optimization can help you reach out to some of the most
                  promising and potential buyers and we can help you with the process. It’s time to succeed.
                </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive aos-init aos-animate" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              < All_BannerForm/>
            </div>
          </div>
        </div>
      </section>


     <Packages/>
     <Portfolio/>

     <section className="web-manage-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="web-img">
                <img loading="lazy" src="images/web-img.png" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-text">
                <h2>
                  Secure, Fast, &amp; High <br /> Performing Websites
                </h2>
                <p>
                  Get your dream WordPress website with us that offers your business more visitors, leads, and
                  profits. Web Craft Solutions is a full-service website development firm that provides
                  turn-key WordPress development solutions for businesses. We handle everything for our valued
                  clients, from design and development to deployment and optimization.
                </p>
              </div>
              <div className="btn-box">
                <a href="javascript:;" className="requestbtn" data-toggle="modal" data-target="#analyse-modal">Request a
                  Quote</a>
                <a href="javascript:;" className="livechatbtn">live chat <i className="fa fa-arrow-circle-o-right" aria-hidden="true" /> </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tech-service-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="tech-text">
                <h2>
                  What Does Our WordPress Development <br /> Plan Include?
                </h2>
                <p>
                  Our WordPress CMS professionals have worked on websites similar to yours, and we can help
                  your business reach <br /> new heights of success. Here is what's included in our WordPress
                  development plan:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="tech-inner">
                  <img loading="lazy" src="images/tech-1.png" />
                  <h3>
                    Website Design <br />
                    Flexibility
                  </h3>
                  <p>WordPress is a platform that allows for a great deal of customization. Whether you want
                    to promote your business, blog, or e-commerce website, our WordPress developers can
                    help!</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-inner">
                  <img src="images/tech-2.png" />
                  <h3>
                    Easy Content <br />
                    Management System
                  </h3>
                  <p>WordPress provides a simple-to-use CMS interface that makes it easy for companies to
                    manage their websites after they have been established.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-inner">
                  <img loading="lazy" src="images/tech-3.png" />
                  <h3>
                    WordPress Plugin <br />
                    Development
                  </h3>
                  <p>The WordPress platform provides over 50,000 plugins to developers in order to construct a
                    fully functional website that is also visually appealing.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="tech-inner">
                  <img src="images/tech-4.png" />
                  <h3>
                    SEO-Friendly <br />
                    Web Design
                  </h3>
                  <p>The platform is intended to make website optimization as simple as possible. The
                    platform-powered websites are extremely SEO-friendly.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-inner">
                  <img src="images/tech-5.png" />
                  <h3>
                    Responsive <br /> Design
                  </h3>
                  <p>Our WordPress developers produce websites that have the greatest degree of functionality
                    and appearance across several devices and browsers.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-inner">
                  <img src="images/tech-6.png" />
                  <h3>
                    Support &amp; <br /> Maintenance
                  </h3>
                  <p>We have a staff of technically proficient developers that work diligently to maintain and
                    upgrade the site to ensure functionality, usability, and peak performance.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Contact/>

    <Footer />
    </>

		)
}