import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Process from 
'../Components/Process';
import Aim from 
'../Components/Aim';
import Quote from 
'../Components/Quote';
import Testimonial from 
'../Components/Testimonial';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';



export default function About(){

  useEffect(() => {
    document.title = 'Web Craft Solutions | About';
}, []);
  
	return(
    <> 
    <Header />
    
    <section id="about-banner" className="inner-banner dark-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-7">
              <div className="inner-banner-text" data-aos="fade-right" data-aos-duration={1000}>
                <h1>About Us</h1>
                <p className="text-white">We want every business to benefit from our experience. To achieve that
                  goal, we are reaching out to every small, mid, or large-scale business which may need our
                  help. </p>
                <a data-toggle="modal" data-target="#analyse-modal" className="theme-btn mb-4 orange-gradient" href="javascript:;">Get a Free Quote</a>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-sm-5">
              <div className="inner-banner-img" data-aos="zoom-in" data-aos-duration={1000}>
                <img src="images/about-banner.png" alt="Image" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
        <span className="blue-wave" data-aos="fade-right" data-aos-duration={1000}>
          <img src="images/box-top-img.png" alt="Image" className="img-responsive" />
        </span>
    </section>

     <section className="logo-rotate struggle-section about-section all-section">
        <div className="container">
          <div className="row order-change align-items-center">
            <div className="col-sm-6">
              <div data-aos="zoom-in" data-aos-duration={1000}>
                <figure>
                  <img src="images/about-inner.png" alt="Image" className="img-responsive" />
                </figure>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="struggle-text" data-aos="fade-left" data-aos-duration={1000}>
                <div className="top-head">
                  <span className="head-upper" />
                  <span className="head-after" />
                </div>
                <h3 className="section-head">Today Every Business Needs To Have An Online Presence</h3>
                <p>Web Craft Solutions is the only online custom web development agency that works
                  internationally and with a 100% refund guarantee. There is no doubt that we are the right
                  service to choose from no matter your location and industry. When you get such a guarantee,
                  there is no reason not to try the service.</p>
                <a className="theme-btn light-gradient" href="javascript:;" data-toggle="modal" data-target="#analyse-modal">Get a Free Quote</a>
              </div>
            </div>
          </div>
        </div>
        <span className="rotate-gsap logo-body" data-aos="fade-up" data-aos-duration={1000}>
          <img src="images/about-logo.png" alt="Image" className="img-responsive" />
        </span>
     </section>

     <Process/>
     <Aim/>
     <Quote/>
     <Testimonial/>
     <Contact/>

    <Footer />
    </>

		)
}