import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';




export default function Smm(){

  setTimeout(() => {
    document.getElementById('nav-branding-tab').click()
  }, 1000);

  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Social Media Marketing';
},[]);
  
	return(
    <> 
    <Header />
    
    <section id="social-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>Social Media Marketing Let's Increase your engagement and Sales</h1>
                <p className="text-white">Allow yourself to lean back and enjoy the taste of success with our highly engaging and professional services. As leading content creators with over a decade of social media marketing experience, we’ve got all the tools you need to drive your business to the success you desire. Our team of experts is skilled in digital marketing and social media advertising and can use powerful tools to enhance your brands visibility in no time. </p>
                <p className="text-white">We strive to meet your requirements in all aspects and complete your goals together. </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
            <All_BannerForm />
            </div>
          </div>
        </div>
    </section>


     <Packages/>

     <div>
        <section className="logo-rotate timeline-section all-section">
          <div className="container">
            <div className="col-md-10 mx-auto">
              <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
                <div className="top-head mx-auto">
                  <span className="head-before" />
                  <span className="head-upper" />
                  <span className="head-after" />
                </div>
                <h3 className="section-head">How do we promote your business?</h3>
              </div>
            </div>
            <div className="timeline-middlepath">
              <div className="row">
                <div className="col-md-2 mx-auto">
                  <div className="timeline-middle">
                    <img src="images/timeline-rocket.png" alt="Image" className="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
            <div className="time-box">
              <div className="row order-change"> 
                <div className="col-md-5 col-sm-6 col-9">
                  <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                    <span>01</span>
                    <span className="box-head text-uppercase">Research</span>
                    <p>It takes a lot of necessary research and ground work to establish a foundation for your brand. We ensure all the right bases are covered and move forward with the best strategy.</p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                  <figure data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/timeline1.jpg" alt="Image" className="img-responsive" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="time-box  right-time">
              <div className="row ">
                <div className="col-md-5 col-sm-6 col-3">
                  <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                    <img src="images/timeline2.png" alt="Image" className="img-responsive" />
                  </figure>
                </div>
                <div className="col-md-5 col-sm-6  col-9 offset-md-2">
                  <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                    <span>02</span>
                    <span className="box-head text-uppercase">Building Influence</span>
                    <p>We use our high-quality content creation skills to attract more traffic to your platform and build an identity for your brand. We make sure to create an influence for your brand that’s built to last.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="time-box">
              <div className="row order-change">
                <div className="col-md-5 col-sm-6 col-9">
                  <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                    <span>03</span>
                    <span className="box-head text-uppercase">Social Media Ad Creation</span>
                    <p>We cover all aspects needed to build your platform with perfection. Our team takes every step necessary to give you the flawless SMM services that you deserve. </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                  <figure data-aos="zoom-in" data-aos-duration={1000}>
                    <img src="images/timeline3.png" alt="Image" className="img-responsive" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="time-box  right-time">
              <div className="row ">
                <div className="col-md-5 col-sm-6 col-3">
                  <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                    <img src="images/timeline4.png" alt="Image" className="img-responsive" />
                  </figure>
                </div>
                <div className="col-md-5 col-sm-6 col-9 offset-md-2">
                  <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                    <span>04</span>
                    <span className="box-head text-uppercase">Monthly Reporting</span>
                    <p>Our work doesn’t end after we’ve managed to create visibility for your brand as we monitor it every month to ensure smooth running and present you with reports. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <span  class="rotate-gsap logo-body" data-aos="fade-up" data-aos-duration="1000"> 
   <img src="images/ranking-img-bg.png" alt="Image" class="img-responsive">
   </span> */}
        </section>
        <section className="grey-bg struggle-section all-section">
          <div className="container">
            <div className="row align-items-center order-change">
              <div className="col-sm-6">
                <div data-aos="zoom-in" data-aos-duration={1000}>
                  <img src="images/social-img.png" alt="Image" className="img-responsive" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="struggle-text" data-aos="fade-left" data-aos-duration={1000}>
                  <div className="top-head">
                    <span className="head-upper" />
                    <span className="head-after" />
                  </div>
                  <h3 className="section-head">Allow us to help you reach your business objectives!</h3>
                  <p>There is a lot of competition for online brands and you need to have an edge to stand out among the crowd. That’s where we come in. Web Craft Solutions can help you breathe life into your vision. Our team of social media experts offers cost-friendly marketing services engineered to drive your brand to the top. </p>
                  <ul className="theme-list">
                    <li><p> Free consultation</p></li>
                    <li><p> Campaign monitoring</p></li>
                    <li><p> Regular reports</p></li>
                    <li><p> Higher leads</p></li>
                    <li><p> Detailed research and analysis</p></li>
                    <li><p> Brand development and growth</p></li>
                  </ul>
                  <a className="theme-btn light-gradient" href="grow-form.html">Lets Grow Your Business</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="ftr-frm" />
    </div>

     <Contact/>

    <Footer />
    </>

		)
}