import Header from
    '../Components/Header';
import Footer from
    '../Components/Footer';
import Contact from
    '../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';



export default function Seo() {

    useEffect(() => {

            window.$(".tablist ul li a").click((function() {
            var t = window.$(this).attr("data-target");
            return window.$(".tablist ul li a").removeClass("active"),
             window.$(this).addClass("active"),
              window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
        }))
        document.title = 'Web Craft Solutions | SEO';
     },[]);

    return (
        <>
            <Header />
           
                <section id="local-seo-banner" className="services-banner inner-banner orange-gradient">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-10 mx-auto">
                                <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                                    <h1>Search Engine Optimization</h1>
                                    <p className="text-white">We aim to provide the most strategic and reliable SEO services for our
                                        clients, which can help elevate your brand to the next level. We have a highly creative team
                                        of industry skilled experts who work together to construct innovative methods and boost your
                                        business in a sustainable manner.
                                    </p>
                                    
                                    <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                < All_BannerForm />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="packages-section all-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
                                    <div className="top-head mx-auto">
                                        <span className="head-before" />
                                        <span className="head-upper" />
                                        <span className="head-after" />
                                    </div>
                                    <h3 className="section-head">Benefit From Our Budget-Friendly Packages.
                                    </h3>
                                    <p>Online business through a website is a necessity of this era. Therefore, we have decided that
                                        we will offer our services to any and every business, regardless of their budget.</p>
                                </div>
                            </div>
                        </div>
                        <nav className="package-tabs" data-aos="fade-right" data-aos-duration={1000}>
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link" id="nav-logo-tab" data-toggle="tab" href="#nav-logo" role="tab" aria-controls="nav-logo" aria-selected="true">Logo Design</a>
                                <a className="nav-item nav-link" id="nav-web-tab" data-toggle="tab" href="#nav-web" role="tab" aria-controls="nav-web" aria-selected="false">Website Design</a>
                                <a className="nav-item nav-link" id="nav-webapp-tab" data-toggle="tab" href="#nav-webapp" role="tab" aria-controls="nav-webapp" aria-selected="false">Web App</a>
                                <a className="nav-item nav-link" id="nav-ecommerce-tab" data-toggle="tab" href="#nav-ecommerce" role="tab" aria-controls="nav-ecommerce" aria-selected="false">E-Commerce</a>
                                <a className="nav-item nav-link active" id="nav-branding-tab" data-toggle="tab" href="#nav-branding" role="tab" aria-controls="nav-branding" aria-selected="false">Digital Marketing</a>
                                <a className="nav-item nav-link" id="nav-video-tab" data-toggle="tab" href="#nav-video" role="tab" aria-controls="nav-video" aria-selected="false">Video Animation</a>
                                <a className="nav-item nav-link" id="nav-shopfiy-tab" data-toggle="tab" href="#nav-shopfiy" role="tab" aria-controls="nav-shopfiy" aria-selected="false">Shopfiy</a>
                                <a className="nav-item nav-link" id="nav-wordpress-tab" data-toggle="tab" href="#nav-wordpress" role="tab" aria-controls="nav-wordpress" aria-selected="false">Wordpress</a>
                                <a className="nav-item nav-link" id="nav-content-tab" data-toggle="tab" href="#nav-content" role="tab" aria-controls="nav-content" aria-selected="false">Content Marketing</a>
                            </div>
                        </nav>
                        {/* BEGIN: PACKAGE SECTION */}
                        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div className="tab-pane fade" id="nav-logo" role="tabpanel" aria-labelledby="nav-logo-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Basic Logo <span>Package</span></h4>
                                                <span className="h6"><del>$70.00 </del>Only</span>
                                                <span className="h5">$35.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                    startups</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />4 Custom Logo Design
                                                        Concepts</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />1 Dedicated Designer
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />4 Revisions</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Final Files Format
                                                        (JPG)</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Unique Design
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership Rights
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SEO - Kick Starter Package  - $199/m" data-price={199.00}>Order
                                                    Now </button>
                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Economy Logo <span>Package</span></h4>
                                                <span className="h6"><del>$178.00 </del>Only</span>
                                                <span className="h5">$89.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />8 Custom Logo Design
                                                        Concepts</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />By 2 Designers</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE Icon</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Revisions
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />All Final Files
                                                        Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Unique Design
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership Rights
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SEO - Start-up Package - $349/m" data-price={349.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Elite Logo <span>Package</span></h4>
                                                <span className="h6"><del>$318.00 </del>Only</span>
                                                <span className="h5">$159.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For pre-established businesses that aim to maintain their
                                                    presence and claim the crown.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Logo Design
                                                        Concepts</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />By 3 Designers</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Revisions
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE Stationary
                                                        Design Set</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE MS Word
                                                        Letterhead</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />All Final Files
                                                        Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership Rights
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Unique Design
                                                        Guarantee</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                {/* <p class="suit-text">$2600 – Quarterly Plan
                     $700 – Recurring Monthly Payment
                  </p> */}
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SEO - Professional Package - $749/m" data-price={749.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>New Business Startup <span>Package</span></h4>
                                                <span className="h6"><del>$798.00 </del>Only</span>
                                                <span className="h5">$399.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                    startups</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Logo Design
                                                        Concepts</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />4-5 Page website</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE Stationary
                                                        Design Set</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Revisions
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE MS Word
                                                        Letterhead</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Free Email Signature
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />All Final Files
                                                        Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership Rights
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Unique Design
                                                        Guarantee</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SEO - Kick Starter Package  - $199/m" data-price={199.00}>Order
                                                    Now </button>
                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Bundle <span>Package</span></h4>
                                                <span className="h6"><del>$1198.00 </del>Only</span>
                                                <span className="h5">$599.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Logo Design
                                                        Concepts</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />By 8 Design Artist
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />E-commerce Website
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                        System</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Stock Photos</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Banner Designs</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />JQuery Slider</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Revisions
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Stationary Design
                                                        Sets</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Double Sided Flyer
                                                        Design</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE MS Word
                                                        Letterhead</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media Banners
                                                        Design</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Free Email Signature
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE Fax Template
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />All Final Files
                                                        Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership Rights
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Unique Design
                                                        Guarantee</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SEO - Start-up Package - $349/m" data-price={349.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Logo <span>Package</span></h4>
                                                <span className="h6"><del>$1198.00 </del>Only</span>
                                                <span className="h5">$799.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Logo Design
                                                        Concepts</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />By 8 Design Artist
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />E-commerce Website
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                        System</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Stock Photos</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Banner Designs</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />JQuery Slider</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />UNLIMITED Revisions
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Stationary Design
                                                        Sets</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Double Sided Flyer
                                                        Design</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE MS Word
                                                        Letterhead</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media Banners
                                                        Design</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />Free Email Signature
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />FREE Fax Template
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />All Final Files
                                                        Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership Rights
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" aria-hidden="true" />100% Unique Design
                                                        Guarantee</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SEO - Start-up Package - $349/m" data-price={349.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-web" role="tabpanel" aria-labelledby="nav-web-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Basic <span>Package</span></h4>
                                                <span className="h6"><del>$794.00 </del>Only</span>
                                                <span className="h5">$199.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                    startups</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> 3 Page Website</li>
                                                    <li><i className="fa fa-check-circle-o" /> 2 Stock Images</li>
                                                    <li><i className="fa fa-check-circle-o" /> 1 jQuery Slider Banner</li>
                                                    <li><i className="fa fa-check-circle-o" /> Contact/Query Form</li>
                                                    <li><i className="fa fa-check-circle-o" /> 48 to 72 hours TAT</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                {/*  <p class="suit-text">$950 – Quarterly Plan
                     $300 – Recurring Monthly Payment
                  </p> */}
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SMM - Kick Starter Package  - $199/m" data-price={199.00}>Order
                                                    Now </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Startup <span>Package</span> </h4>
                                                <span className="h6"><del>$1294.00 </del>Only</span>
                                                <span className="h5">$349.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> 5 Page Website</li>
                                                    <li><i className="fa fa-check-circle-o" /> 5 Stock Photos</li>
                                                    <li><i className="fa fa-check-circle-o" /> 3 Banner Design</li>
                                                    <li><i className="fa fa-check-circle-o" /> 1 jQuery Slider Banner</li>
                                                    <li><i className="fa fa-check-circle-o" /> FREE Google Friendly Sitemap</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" /> 48 to 72 hours TAT</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SMM - Scaling Plan - $349/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Professional <br /> Package </h4>
                                                <span className="h6"><del>$1694.00 </del>Only</span>
                                                <span className="h5">$649.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For pre-established businesses that aim to maintain their
                                                    presence and claim the crown.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> 10 Unique Pages Website</li>
                                                    <li><i className="fa fa-check-circle-o" /> CMS / Admin Panel Support</li>
                                                    <li><i className="fa fa-check-circle-o" /> 8 Stock images</li>
                                                    <li><i className="fa fa-check-circle-o" /> 5 Banner Designs</li>
                                                    <li><i className="fa fa-check-circle-o" /> Mobile Responsive</li>
                                                    <li><i className="fa fa-check-circle-o" /> 1 jQuery Slider Banner</li>
                                                    <li><i className="fa fa-check-circle-o" /> FREE Google Friendly Sitemap</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" /> 48 to 72 hours TAT</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                {/* <p class="suit-text">$2600 – Quarterly Plan
                     $700 – Recurring Monthly Payment
                  </p> */}
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SMM - Venture Plan - $699/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Elite <span>Package</span></h4>
                                                <span className="h6"><del>$2594.00 </del>Only</span>
                                                <span className="h5">$1249.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                    startups</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" />Upto 15 Unique Pages Website</li>
                                                    <li><i className="fa fa-check-circle-o" />Conceptual and Dynamic Website</li>
                                                    <li><i className="fa fa-check-circle-o" />Mobile Responsive</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Reservation/Appointment Tool
                                                        (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Payment Integration (Optional)
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Forms</li>
                                                    <li><i className="fa fa-check-circle-o" />Lead Capturing Forms (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Striking Hover Effects</li>
                                                    <li><i className="fa fa-check-circle-o" />Newsletter Subscription (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Newsfeed Integration</li>
                                                    <li><i className="fa fa-check-circle-o" />Social Media Integration</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Engine Submission</li>
                                                    <li><i className="fa fa-check-circle-o" />5 Stock Photos</li>
                                                    <li><i className="fa fa-check-circle-o" />3 Unique Banner Design</li>
                                                    <li><i className="fa fa-check-circle-o" />1 jQuery Slider Banner</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" />48 to 72 hours TAT</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                {/*  <p class="suit-text">$950 – Quarterly Plan
                     $300 – Recurring Monthly Payment
                  </p> */}
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SMM - Kick Starter Package  - $199/m" data-price={199.00}>Order
                                                    Now </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Corporate<br /> Package </h4>
                                                <span className="h6"><del>$3294.00 </del>Only</span>
                                                <span className="h5">$1949.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" />15 to 20 Pages Website</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Made, Interactive, Dynamic &amp;
                                                        High End Design</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom WP (or) Custom PHP Development
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />1 jQuery Slider Banner</li>
                                                    <li><i className="fa fa-check-circle-o" />Up to 10 Custom Made Banner Designs</li>
                                                    <li><i className="fa fa-check-circle-o" />10 Stock Images</li>
                                                    <li><i className="fa fa-check-circle-o" />Unlimited Revisions</li>
                                                    <li><i className="fa fa-check-circle-o" />Special Hoover Effects</li>
                                                    <li><i className="fa fa-check-circle-o" />Content Management System (CMS)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Appointment/Scheduling/Online
                                                        Ordering Integration (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Payment Integration (Optional)
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />Multi Lingual (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Dynamic Forms (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Signup Area (For Newsletters, Offers
                                                        etc.)</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Bar</li>
                                                    <li><i className="fa fa-check-circle-o" />Live Feeds of Social Networks
                                                        integration (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Mobile Responsive</li>
                                                    <li><i className="fa fa-check-circle-o" />FREE 5 Years Domain Name</li>
                                                    <li><i className="fa fa-check-circle-o" />Free Google Friendly Sitemap</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Engine Submission</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" />Industry Specified Team of Expert
                                                        Designers and Developers</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" />Dedicated Accounts Manager</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Ownership Rights</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SMM - Scaling Plan - $349/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Business <br /> Package </h4>
                                                <span className="h6"><del>$3294.00 </del>Only</span>
                                                <span className="h5">$4999.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For pre-established businesses that aim to maintain their
                                                    presence and claim the crown.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" />15 to 20 Pages Website</li>
                                                    <li><i className="fa fa-check-circle-o" />15 Seconds 2D Explainer Video</li>
                                                    <li><i className="fa fa-check-circle-o" />Voice - Over &amp; Sound Effects</li>
                                                    <li><i className="fa fa-check-circle-o" />Professional Script Writing</li>
                                                    <li><i className="fa fa-check-circle-o" />Storyboard</li>
                                                    <li><i className="fa fa-check-circle-o" />SEO Meta Tags</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Made, Interactive, Dynamic &amp;
                                                        High End Design</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom WP (or) Custom PHP Development
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />1 jQuery Slider Banner</li>
                                                    <li><i className="fa fa-check-circle-o" />Up to 10 Custom Made Banner Designs</li>
                                                    <li><i className="fa fa-check-circle-o" />10 Stock Images</li>
                                                    <li><i className="fa fa-check-circle-o" />Unlimited Revisions</li>
                                                    <li><i className="fa fa-check-circle-o" />Special Hoover Effects</li>
                                                    <li><i className="fa fa-check-circle-o" />Content Management System (CMS)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Appointment/Scheduling/Online
                                                        Ordering Integration (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Payment Integration (Optional)
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />Multi Lingual (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Dynamic Forms (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Signup Area (For Newsletters, Offers
                                                        etc.)</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Bar</li>
                                                    <li><i className="fa fa-check-circle-o" />Live Feeds of Social Networks
                                                        integration (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Mobile Responsive</li>
                                                    <li><i className="fa fa-check-circle-o" />FREE 5 Years Domain Name</li>
                                                    <li><i className="fa fa-check-circle-o" />Free Google Friendly Sitemap</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Engine Submission</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" />Industry Specified Team of Expert
                                                        Designers and Developers</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" />Dedicated Accounts Manager</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Ownership Rights</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" />100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                {/* <p class="suit-text">$2600 – Quarterly Plan
                     $700 – Recurring Monthly Payment
                  </p> */}
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="SMM - Venture Plan - $699/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-webapp" role="tabpanel" aria-labelledby="nav-webapp-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head plan-head-2 text-center">
                                                <h4>Automated/Interactive Conferencing Portal Package</h4>
                                                <span className="h6"><del>$13000.00 </del>Only</span>
                                                <span className="h5">$4999.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                    startups</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /><strong>Upto 40 </strong> Stock images
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /><strong>10</strong>Unique Banner Designs
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /> Unlimited Page Website</li>
                                                    <li><i className="fa fa-check-circle-o" /> Custom Content Management System (CMS)
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /> Unique Pages and UI Design</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Custom Development</li>
                                                    <li><i className="fa fa-check-circle-o" />Process Automation Tools</li>
                                                    <li><i className="fa fa-check-circle-o" />Newsfeed Integration</li>
                                                    <li><i className="fa fa-check-circle-o" />Social Media Plugins Integration</li>
                                                    <li><i className="fa fa-check-circle-o" />JQuery Slider</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Engine Submission</li>
                                                    <li><i className="fa fa-check-circle-o" />Free Google Friendly Sitemap</li>
                                                    <li><i className="fa fa-check-circle-o" /><strong>5 Years</strong> FREE Hosting
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Email Addresses</li>
                                                    <li><i className="fa fa-check-circle-o" />Social Media Page Designs (Facebook,
                                                        Twitter, Instagram)</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" /><strong>100%</strong> Satisfaction
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /><strong>100%</strong> Unique Design
                                                        Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /><strong>100%</strong> Money Back
                                                        Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
                                                {/* <p class="suit-text">$1800 – Quarterly Plan
                     $600 – Recurring Monthly Payment
                  </p> */}
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Web Content - Startup Plan - $500/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head plan-head-2 text-center">
                                                <h4>Automated/Interactive E-Commerce Package</h4>
                                                <span className="h6"><del>$17500.00 </del>Only</span>
                                                <span className="h5">$8999.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li> <i className="fa fa-check-circle-o" /><strong>Upto 40</strong> Stock images
                                                    </li>
                                                    <li> <i className="fa fa-check-circle-o" /><strong>10</strong> Unique Banner
                                                        Designs</li>
                                                    <li> <i className="fa fa-check-circle-o" />Unlimited Page Website</li>
                                                    <li> <i className="fa fa-check-circle-o" />Custom Content Management System (CMS)
                                                    </li>
                                                    <li> <i className="fa fa-check-circle-o" />Unique Pages and UI Design</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Process Automation Tools</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Newsfeed Integration</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Social Media Plugins Integration</li>
                                                    <li> <i className="fa fa-check-circle-o" /> JQuery Slider</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Search Engine Submission</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Free Google Friendly Sitemap</li>
                                                    <li> <i className="fa fa-check-circle-o" /><strong>5 Years</strong> FREE Hosting
                                                    </li>
                                                    <li> <i className="fa fa-check-circle-o" /> Custom Email Addresses</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Social Media Page Designs (Facebook,
                                                        Twitter, Instagram)</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Complete W3C Certified HTML</li>
                                                    <li> <i className="fa fa-check-circle-o" /> Complete Deployment</li>
                                                    <li> <i className="fa fa-check-circle-o" /> <strong>100%</strong> Satisfaction
                                                        Guarantee</li>
                                                    <li> <i className="fa fa-check-circle-o" /> <strong>100%</strong> Unique Design
                                                        Guarantee</li>
                                                    <li> <i className="fa fa-check-circle-o" /> <strong>100%</strong> Money Back
                                                        Guarantee</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
              
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Professional Website Development - $199.00" data-price={199.00}>Order Now </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head plan-head-2 text-center">
                                                <h4>Custom CRM/ERP Portal Website Package</h4>
                                                <span className="h6"><del>$17500.00 </del>Only</span>
                                                <span className="h5">$12999.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For pre-established businesses that aim to maintain their
                                                    presence and claim the crown.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> Unlimited Page Website</li>
                                                    <li><i className="fa fa-check-circle-o" /> Unique Pages and UI Design</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete Custom Development</li>
                                                    <li><i className="fa fa-check-circle-o" /> Newsfeed Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> CRM (Customer Relation Management
                                                        System)</li>
                                                    <li><i className="fa fa-check-circle-o" /> Performance and analytics</li>
                                                    <li><i className="fa fa-check-circle-o" /> Customization of Personal Details</li>
                                                    <li><i className="fa fa-check-circle-o" /> Process management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Sales Automation</li>
                                                    <li><i className="fa fa-check-circle-o" /> Team Collaboration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Marketing Automation</li>
                                                    <li><i className="fa fa-check-circle-o" /> Security</li>
                                                    <li><i className="fa fa-check-circle-o" /> Integrations</li>
                                                    <li><i className="fa fa-check-circle-o" /> Mobile Notifications</li>
                                                    <li><i className="fa fa-check-circle-o" /> Sales Reports</li>
                                                    <li><i className="fa fa-check-circle-o" /> Trend Analytics</li>
                                                    <li><i className="fa fa-check-circle-o" /> Forecasting</li>
                                                    <li><i className="fa fa-check-circle-o" /> Territory Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Account Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Event Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Advanced Data Security</li>
                                                    <li><i className="fa fa-check-circle-o" /> Opportunity Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Sales Forecasting</li>
                                                    <li><i className="fa fa-check-circle-o" /> Call/Video Logging</li>
                                                    <li><i className="fa fa-check-circle-o" /> Quotes</li>
                                                    <li><i className="fa fa-check-circle-o" /> Contracts</li>
                                                    <li><i className="fa fa-check-circle-o" /> Document Library</li>
                                                    <li><i className="fa fa-check-circle-o" /> Case Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Analytics and Dashboards</li>
                                                    <li><i className="fa fa-check-circle-o" /> Lead Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Resource Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Analytics</li>
                                                    <li><i className="fa fa-check-circle-o" /> Web Intelligence</li>
                                                    <li><i className="fa fa-check-circle-o" /> Automated Emails, Invoices &amp;
                                                        Estimates</li>
                                                    <li><i className="fa fa-check-circle-o" /> Automated Split invoicing</li>
                                                    <li><i className="fa fa-check-circle-o" /> Automated Combine invoices</li>
                                                    <li><i className="fa fa-check-circle-o" /> Invoice templates</li>
                                                    <li><i className="fa fa-check-circle-o" /> Financial Reports</li>
                                                    <li><i className="fa fa-check-circle-o" /> Generate extremely detailed reports for
                                                        your sales and services. Filter your reports by date-range and category to
                                                        see what's making you the most money.</li>
                                                    <li><i className="fa fa-check-circle-o" /> Generate automated sales reports</li>
                                                    <li><i className="fa fa-check-circle-o" /> Core Modules</li>
                                                    <li><i className="fa fa-check-circle-o" /> Human Resources</li>
                                                    <li><i className="fa fa-check-circle-o" /> Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Business Intelligence</li>
                                                    <li><i className="fa fa-check-circle-o" /> Sales/Marketing</li>
                                                    <li><i className="fa fa-check-circle-o" /> Finance</li>
                                                    <li><i className="fa fa-check-circle-o" /> Core Features</li>
                                                    <li><i className="fa fa-check-circle-o" /> Reporting</li>
                                                    <li><i className="fa fa-check-circle-o" /> Accounting</li>
                                                    <li><i className="fa fa-check-circle-o" /> Tracking and Visibility</li>
                                                    <li><i className="fa fa-check-circle-o" /> Centralized Modules</li>
                                                    <li><i className="fa fa-check-circle-o" /> ERP Database</li>
                                                    <li><i className="fa fa-check-circle-o" /> Human Resources Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Business Process Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> Enterprise Analytics</li>
                                                    <li><i className="fa fa-check-circle-o" /> Business Intelligence</li>
                                                    <li><i className="fa fa-check-circle-o" /> Centralized Modules</li>
                                                    <li><i className="fa fa-check-circle-o" /> ERP Database</li>
                                                    <li><i className="fa fa-check-circle-o" /> Integrations</li>
                                                    <li><i className="fa fa-check-circle-o" /> If (Manufacturing) (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" /> Accounting</li>
                                                    <li><i className="fa fa-check-circle-o" /> Distribution</li>
                                                    <li><i className="fa fa-check-circle-o" /> Business Intelligence</li>
                                                    <li><i className="fa fa-check-circle-o" /> Insights</li>
                                                    <li><i className="fa fa-check-circle-o" /> Standardization</li>
                                                    <li><i className="fa fa-check-circle-o" /> Procurement</li>
                                                    <li><i className="fa fa-check-circle-o" /> Reporting and Analytics</li>
                                                    <li><i className="fa fa-check-circle-o" /> Forecasting</li>
                                                    <li><i className="fa fa-check-circle-o" /> Projection</li>
                                                    <li><i className="fa fa-check-circle-o" /> Enterprise-wide integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Real-Time Operations</li>
                                                    <li><i className="fa fa-check-circle-o" /> Problem definition</li>
                                                    <li><i className="fa fa-check-circle-o" /> Description of the program’s objectives
                                                        and scope</li>
                                                    <li><i className="fa fa-check-circle-o" /> Assumptions</li>
                                                    <li><i className="fa fa-check-circle-o" /> Implementation costs</li>
                                                    <li><i className="fa fa-check-circle-o" /> Implementation schedule</li>
                                                    <li><i className="fa fa-check-circle-o" /> Development and operational risks</li>
                                                    <li><i className="fa fa-check-circle-o" /> Projected benefits</li>
                                                    <li><i className="fa fa-check-circle-o" /> Team Members</li>
                                                    <li><i className="fa fa-check-circle-o" /> Contracts</li>
                                                    <li><i className="fa fa-check-circle-o" /> Infrastructure Upgrades</li>
                                                    <li><i className="fa fa-check-circle-o" /> Create work plans and timelines</li>
                                                    <li><i className="fa fa-check-circle-o" /> Analyze gaps</li>
                                                    <li><i className="fa fa-check-circle-o" /> Configure parameters</li>
                                                    <li><i className="fa fa-check-circle-o" /> Migrate data</li>
                                                    <li><i className="fa fa-check-circle-o" /> Test system</li>
                                                    <li><i className="fa fa-check-circle-o" /> Document system</li>
                                                    <li><i className="fa fa-check-circle-o" /> Advanced Admin Features 2.0</li>
                                                    <li><i className="fa fa-check-circle-o" /> User Signup/Login Functionalities</li>
                                                    <li><i className="fa fa-check-circle-o" /> Advanced User Features</li>
                                                    <li><i className="fa fa-check-circle-o" /> User Profile Management</li>
                                                    <li><i className="fa fa-check-circle-o" /> General Configuration Features</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete W3C Certified HTML</li>
                                                    <li><i className="fa fa-check-circle-o" /> Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> Money Back Guarantee</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
             
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Professional Website Development - $199.00" data-price={199.00}>Order Now </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-ecommerce" role="tabpanel" aria-labelledby="nav-ecommerce-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>E-Commerce Basic Package</h4>
                                                <span className="h6"><del>$1549.00 </del>Only</span>
                                                <span className="h5">$749.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                    startups</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> Customized Design</li>
                                                    <li><i className="fa fa-check-circle-o" /> Upto 50 Products </li>
                                                    <li><i className="fa fa-check-circle-o" /> Content Management System (CMS)</li>
                                                    <li><i className="fa fa-check-circle-o" /> Shopping Cart Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Payment Module Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Easy Product Search</li>
                                                    <li><i className="fa fa-check-circle-o" /> Dedicated designer &amp; developer</li>
                                                    <li><i className="fa fa-check-circle-o" /> Unlimited Revisions</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guaranteed</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Money Back Guarantee*</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Web Content - Startup Plan - $199/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>E-Commerce Plus Package</h4>
                                                <span className="h6"><del>$2799.00 </del>Only</span>
                                                <span className="h5">$1249.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                    corporate ladder.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> Customized Design</li>
                                                    <li><i className="fa fa-check-circle-o" /> Up-to 500 Products</li>
                                                    <li><i className="fa fa-check-circle-o" /> Content Management System (CMS)</li>
                                                    <li><i className="fa fa-check-circle-o" /> Full Shopping Cart Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Payment Module Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Easy Product Search</li>
                                                    <li><i className="fa fa-check-circle-o" /> Product Reviews</li>
                                                    <li><i className="fa fa-check-circle-o" /> 5 Promotional Banners </li>
                                                    <li><i className="fa fa-check-circle-o" /> Team of Expert Designers &amp;
                                                        Developers</li>
                                                    <li><i className="fa fa-check-circle-o" /> Unlimited Revisions</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Unique Design Guarantee </li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Money Back Guarantee* </li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">
              
                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Web Content - Scaling Plan - $399/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Elite E-Commerce Package</h4>
                                                <span className="h6"><del>$7399.00 </del>Only</span>
                                                <span className="h5">$1999.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For pre-established businesses that aim to maintain their
                                                    presence and claim the crown.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" /> Unlimited Pages Website with Unique
                                                        Design</li>
                                                    <li><i className="fa fa-check-circle-o" /> 5 Custom Logo Design</li>
                                                    <li><i className="fa fa-check-circle-o" /> CMS / Backend Administrative System
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /> Unlimited Products with Unlimited
                                                        Categories</li>
                                                    <li><i className="fa fa-check-circle-o" /> FREE Unlimited Revisions</li>
                                                    <li><i className="fa fa-check-circle-o" /> Custom Shopping Cart Integration</li>
                                                    <li><i className="fa fa-check-circle-o" /> Multiple Payment Module Integration
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /> Multiple Shipping Module Integration
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /> Product Ratings &amp; Reviews</li>
                                                    <li><i className="fa fa-check-circle-o" /> Sales &amp; Inventory Management System
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" /> Multiple Currency Support</li>
                                                    <li><i className="fa fa-check-circle-o" /> Customer Login Area (Sign-Up &amp;
                                                        Sign-In)</li>
                                                    <li><i className="fa fa-check-circle-o" /> Mobile Responsive</li>
                                                    <li><i className="fa fa-check-circle-o" /> Social Media Designs (Facebook,
                                                        Twitter, YouTube)</li>
                                                    <li><i className="fa fa-check-circle-o" /> Dedicated Team of Expert Designers
                                                        &amp; Developers</li>
                                                    <li><i className="fa fa-check-circle-o" /> Dedicated Project Manager</li>
                                                    <li><i className="fa fa-check-circle-o" /> News Letter Subscription</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa fa-check-circle-o" /> 100% Money Back Guarantee *</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Web Content - Venture Plan - $699/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                            <div className="plan-head text-center">
                                                <h4>Ecommerce Business Package</h4>
                                                <span className="h6"><del>$11000.00 </del>Only</span>
                                                <span className="h5">$4449.00 USD</span>
                                            </div>
                                            <div className="package-list">
                                                <p className="suit-text">For pre-established businesses that aim to maintain their
                                                    presence and claim the crown.</p>
                                                <ul className="pkg-opt mt-3 mb-3">
                                                    <li><i className="fa fa-check-circle-o" />Complete Custom Design &amp; Development
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />Unlimited Products / Service Pages</li>
                                                    <li><i className="fa fa-check-circle-o" />Unique, User Friendly, Interactive,
                                                        Dynamic, High End UI Design</li>
                                                    <li><i className="fa fa-check-circle-o" />Unlimited Banner Designs</li>
                                                    <li><i className="fa fa-check-circle-o" />Interactive Sliding Banners</li>
                                                    <li><i className="fa fa-check-circle-o" />Special Hover Effects</li>
                                                    <li><i className="fa fa-check-circle-o" />Customized Contact Us Form</li>
                                                    <li><i className="fa fa-check-circle-o" />Multiple Filtration Option (Search By
                                                        Age, Experience, Talent, Industry Etc)</li>
                                                    <li><i className="fa fa-check-circle-o" />Profile Comparison (As Per Category)
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />File Converter</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Video Functionality</li>
                                                    <li><i className="fa fa-check-circle-o" />Multiple File Format Uploading</li>
                                                    <li><i className="fa fa-check-circle-o" />User Signup Area ( Talent )</li>
                                                    <li><i className="fa fa-check-circle-o" />User Signup Area ( Casting )</li>
                                                    <li><i className="fa fa-check-circle-o" />User Signup Area ( Agency )</li>
                                                    <li><i className="fa fa-check-circle-o" />Client/User Dashboard Area</li>
                                                    <li><i className="fa fa-check-circle-o" />Vendor / Agency Dashboard Area</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Coding And Development</li>
                                                    <li><i className="fa fa-check-circle-o" />Content Management System (Custom)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Appointment/Scheduling
                                                        Integration (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Online Payment Integration</li>
                                                    <li><i className="fa fa-check-circle-o" />Invoicing System</li>
                                                    <li><i className="fa fa-check-circle-o" />Automated Email Notifications</li>
                                                    <li><i className="fa fa-check-circle-o" />Multi Lingual (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Custom Dynamic Forms</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Database Creation</li>
                                                    <li><i className="fa fa-check-circle-o" />3rd Party Links Integration (Instagram,
                                                        IMDB Etc )</li>
                                                    <li><i className="fa fa-check-circle-o" />Signup Automated Email Authentication
                                                    </li>
                                                    <li><i className="fa fa-check-circle-o" />Signup Area (For Newsletters, Offers
                                                        Etc.)</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Bar For Easy Search</li>
                                                    <li><i className="fa fa-check-circle-o" />Live Feeds Of Social Networks
                                                        Integration (Optional)</li>
                                                    <li><i className="fa fa-check-circle-o" />Search Engine Submission</li>
                                                    <li><i className="fa fa-check-circle-o" />SEO Friendly</li>
                                                    <li><i className="fa fa-check-circle-o" />Mobile Responsive</li>
                                                    <li><i className="fa fa-check-circle-o" />Master Admin Panel</li>
                                                    <li><i className="fa fa-check-circle-o" />SSL Certification ( To Make The Website
                                                        And It’s Informative Secured )</li>
                                                    <li><i className="fa fa-check-circle-o" />1 Year Free Hosting</li>
                                                    <li><i className="fa fa-check-circle-o" />6 Months Of Free Maintenance</li>
                                                    <li><i className="fa fa-check-circle-o" />Award Winning Team Of Expert Designers
                                                        And Developers</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete Deployment</li>
                                                    <li><i className="fa fa-check-circle-o" />Complete W3C Validation</li>
                                                    <li><i className="fa fa-check-circle-o" />Dedicated Team Of Designers And
                                                        Developers</li>
                                                </ul>
                                            </div>
                                            <div className="text-center package-mid">

                                                <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Web Content - Venture Plan - $699/m" data-price={199.00}>Order Now
                                                </button>

                                            </div>
                                            <div className="package-footer d-flex justify-content-between">
                                                <div className="bd-right">
                                                    <p>Share your idea?</p>
                                                    <a href="tel:+1234567891">1234567891</a>
                                                </div>
                                                <div>
                                                    <p>Want to discuss?</p>
                                                    <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show active" id="nav-branding" role="tabpanel" aria-labelledby="nav-branding-tab">
                                <div className="tablist">
                                    <ul>
                                        <li><a href="javascript:;" data-target="tab-1">SMM</a></li>
                                        <li><a href="javascript:;" className="active" data-target="tab-2">Seo</a></li>
                                    </ul>
                                </div>
                                <div className="tabmain">
                                    <div className="tabbox" id="tab-1">
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="plan-head text-center">
                                                        <h4>Simple Start Basic</h4>
                                                        {/*<span class="h6"><del>$1598.00 </del>Only</span>*/}
                                                        <span className="h5">$350</span>
                                                    </div>
                                                    <div className="package-list">
                                                        <p className="suit-text">Suitable for newly formed organizations or small
                                                            incubated startups</p>
                                                        <ul className="pkg-opt mt-3 mb-3">
                                                            <li><i className="fa fa-check-circle-o" />3 postings per week (per
                                                                network) Facebook + Twitter + Instagram + Google+</li>
                                                            <li><i className="fa fa-check-circle-o" />Content Creation</li>
                                                            <li><i className="fa fa-check-circle-o" />Business Page Optimization</li>
                                                            <li><i className="fa fa-check-circle-o" />Social Media Strategy (Overview)
                                                            </li>
                                                            <li><i className="fa fa-check-circle-o" />Facebook Likes Campaign</li>
                                                            <li><i className="fa fa-check-circle-o" />Monthly Progress report</li>
                                                            <li><i className="fa fa-check-circle-o" />Copy Writing</li>
                                                        </ul>
                                                    </div>
                                                    <div className="text-center package-mid">

                                                        <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                                    </div>
                                                    <div className="package-footer d-flex justify-content-between">
                                                        <div className="bd-right">
                                                            <p>Share your idea?</p>
                                                            <a href="tel:+1234567891">1234567891</a>
                                                        </div>
                                                        <div>
                                                            <p>Want to discuss?</p>
                                                            <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="plan-head text-center">
                                                        <h4>Essentials Startup</h4>
                                                        {/*<span class="h6"><del>$2198.00 </del>Only</span>*/}
                                                        <span className="h5">$750</span>
                                                    </div>
                                                    <div className="package-list">
                                                        <p className="suit-text">For medium-sized stable organizations looking to climb
                                                            up the corporate ladder.</p>
                                                        <ul className="pkg-opt mt-3 mb-3">
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />60 Second
                                                                Video</li>
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional
                                                                Script</li>
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme
                                                            </li>
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard
                                                            </li>
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation
                                                            </li>
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over
                                                                &amp; Sound Effects</li>
                                                            <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 weeks
                                                                Delivery</li>
                                                        </ul>
                                                    </div>
                                                    <div className="text-center package-mid">

                                                        <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order Now </button>

                                                    </div>
                                                    <div className="package-footer d-flex justify-content-between">
                                                        <div className="bd-right">
                                                            <p>Share your idea?</p>
                                                            <a href="tel:+1234567891">1234567891</a>
                                                        </div>
                                                        <div>
                                                            <p>Want to discuss?</p>
                                                            <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="plan-head text-center">
                                                        <h4>Business Plus Professional</h4>
                                                        {/*<span class="h6"><del>$4598.00 </del>Only</span>*/}
                                                        <span className="h5">$1250</span>
                                                    </div>
                                                    <div className="package-list">
                                                        <p className="suit-text">For pre-established businesses that aim to maintain
                                                            their presence and claim the crown.</p>
                                                        <ul className="pkg-opt mt-3 mb-3">
                                                            <li><i className="fa fa-check-circle-o" />Copywriting &amp; Visual designs
                                                            </li>
                                                            <li><i className="fa fa-check-circle-o" />Business Page Optimization</li>
                                                            <li><i className="fa fa-check-circle-o" />Ad Campaign Management</li>
                                                            <li><i className="fa fa-check-circle-o" />Spam monitoring</li>
                                                            <li><i className="fa fa-check-circle-o" />6 postings per week Facebook +
                                                                Twitter + Instagram + Google+</li>
                                                            <li><i className="fa fa-check-circle-o" />Reputation Management</li>
                                                            <li><i className="fa fa-check-circle-o" />Social Account Setup</li>
                                                            <li><i className="fa fa-check-circle-o" />Content Creation</li>
                                                            <li><i className="fa fa-check-circle-o" />Social Media Hearing</li>
                                                            <li><i className="fa fa-check-circle-o" />Query and comments reply</li>
                                                        </ul>
                                                    </div>
                                                    <div className="text-center package-mid">

                                                        <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order Now </button>

                                                    </div>
                                                    <div className="package-footer d-flex justify-content-between">
                                                        <div className="bd-right">
                                                            <p>Share your idea?</p>
                                                            <a href="tel:+1234567891">1234567891</a>
                                                        </div>
                                                        <div>
                                                            <p>Want to discuss?</p>
                                                            <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabbox" id="tab-2" style={{ display: 'block' }}>
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                                <div className="plan-head text-center">
                                                    <h4>Simple Start Basic</h4>
                                                    {/*<span class="h6"><del>$1598.00 </del>Only</span>*/}
                                                    <span className="h5">$350</span>
                                                </div>
                                                <div className="package-list">
                                                    <p className="suit-text">Suitable for newly formed organizations or small
                                                        incubated startups</p>
                                                    <ul className="pkg-opt mt-3 mb-3">
                                                        <li><i className="fa fa-check-circle-o" /><b>Campaign Setup And
                                                            Optimization</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Website Audit </li>
                                                        <li><i className="fa fa-check-circle-o" />Pages Optimized (10 pages) </li>
                                                        <li><i className="fa fa-check-circle-o" />15 Selected Keywords Targeting
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Keyword Research</li>
                                                        <li><i className="fa fa-check-circle-o" />Keyword Grouping</li>
                                                        <li><i className="fa fa-check-circle-o" />Keyword Mapping</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>On-Page Optimization</b></li>
                                                        <li><i className="fa fa-check-circle-o" />SEO Road Map</li>
                                                        <li><i className="fa fa-check-circle-o" />Blog Creation</li>
                                                        <li><i className="fa fa-check-circle-o" />Webpage Copywriting (3 pages,
                                                            350 words per page)</li>
                                                        <li><i className="fa fa-check-circle-o" />Title Tag Optimization (10
                                                            titles)</li>
                                                        <li><i className="fa fa-check-circle-o" />Meta Description Optimization
                                                            (10 meta description)</li>
                                                        <li><i className="fa fa-check-circle-o" />Meta Keyword Optimization (10
                                                            meta keywords)</li>
                                                        <li><i className="fa fa-check-circle-o" />Domain Redirect Optimization (10
                                                            domain redirects)</li>
                                                        <li><i className="fa fa-check-circle-o" />xml Sitemap Optimization</li>
                                                        <li><i className="fa fa-check-circle-o" />Robots.txt Check</li>
                                                        <li><i className="fa fa-check-circle-o" />URL Rewrites (10 URL rewrites)
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Broken Link Report</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Rich Snippet
                                                            Recommendations</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Breadcrumbs</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Initial Off-Page SEO</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Social Bookmarking</li>
                                                        <li><i className="fa fa-check-circle-o" />Slide Share Marketing </li>
                                                        <li><i className="fa fa-check-circle-o" />Forums/FAQ’s </li>
                                                        <li><i className="fa fa-check-circle-o" />Link Building</li>
                                                        <li><i className="fa fa-check-circle-o" />Directory Submission</li>
                                                        <li><i className="fa fa-check-circle-o" />Local Business Listings</li>
                                                    </ul>
                                                </div>
                                                <div className="text-center package-mid">

                                                    <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                                </div>
                                                <div className="package-footer d-flex justify-content-between">
                                                    <div className="bd-right">
                                                        <p>Share your idea?</p>
                                                        <a href="tel:+1234567891">1234567891</a>
                                                    </div>
                                                    <div>
                                                        <p>Want to discuss?</p>
                                                        <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                                <div className="plan-head text-center">
                                                    <h4>Essentials Startup</h4>
                                                    {/*<span class="h6"><del>$2198.00 </del>Only</span>*/}
                                                    <span className="h5">$750</span>
                                                </div>
                                                <div className="package-list">
                                                    <p className="suit-text">For medium-sized stable organizations looking to climb
                                                        up the corporate ladder.</p>
                                                    <ul className="pkg-opt mt-3 mb-3">
                                                        <li><i className="fa fa-check-circle-o" /><b>Prior Analysis</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Business Analysis </li>
                                                        <li><i className="fa fa-check-circle-o" />Consumer Analysis </li>
                                                        <li><i className="fa fa-check-circle-o" />35 Selected Keywords Targeting
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />15 Pages Keyword Targeted</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Webpage Optimization</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Meta Tags Creation</li>
                                                        <li><i className="fa fa-check-circle-o" />Keyword Optimization</li>
                                                        <li><i className="fa fa-check-circle-o" />Image Optimization</li>
                                                        <li><i className="fa fa-check-circle-o" />Inclusion of anchors</li>
                                                        <li><i className="fa fa-check-circle-o" /><b> Tracking &amp; Analysis</b>
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" /> Google Analytics Installation
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Google Webmaster Installation
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Call To Action Plan</li>
                                                        <li><i className="fa fa-check-circle-o" />Creation of Sitemaps</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Reporting</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Monthly Reporting</li>
                                                        <li><i className="fa fa-check-circle-o" />Recommendation</li>
                                                        <li><i className="fa fa-check-circle-o" />Email Support</li>
                                                        <li><i className="fa fa-check-circle-o" />Phone Support</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Off Page Optimization</b>
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Social Bookmarking</li>
                                                        <li><i className="fa fa-check-circle-o" />Slide Share Marketing</li>
                                                        <li><i className="fa fa-check-circle-o" />Forums/FAQ’s</li>
                                                        <li><i className="fa fa-check-circle-o" />Link Building</li>
                                                        <li><i className="fa fa-check-circle-o" />Directory Submission</li>
                                                        <li><i className="fa fa-check-circle-o" />Local Business Listings</li>
                                                    </ul>
                                                </div>
                                                <div className="text-center package-mid">

                                                    <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order Now </button>

                                                </div>
                                                <div className="package-footer d-flex justify-content-between">
                                                    <div className="bd-right">
                                                        <p>Share your idea?</p>
                                                        <a href="tel:+1234567891">1234567891</a>
                                                    </div>
                                                    <div>
                                                        <p>Want to discuss?</p>
                                                        <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6">
                                            <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                                <div className="plan-head text-center">
                                                    <h4>Business Plus Professional</h4>
                                                    {/*<span class="h6"><del>$4598.00 </del>Only</span>*/}
                                                    <span className="h5">$1250</span>
                                                </div>
                                                <div className="package-list">
                                                    <p className="suit-text">For pre-established businesses that aim to maintain
                                                        their presence and claim the crown.</p>
                                                    <ul className="pkg-opt mt-3 mb-3">
                                                        <li><i className="fa fa-check-circle-o" /><b>Prior Analysis</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Business Analysis</li>
                                                        <li><i className="fa fa-check-circle-o" />Consumer Analysis</li>
                                                        <li><i className="fa fa-check-circle-o" />Competitor Analysis</li>
                                                        <li><i className="fa fa-check-circle-o" />60+ Selected Keywords Targeting
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />30 Pages Keyword Targeted</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Webpage Optimization</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Meta Tags Creation</li>
                                                        <li><i className="fa fa-check-circle-o" />Keyword Optimization</li>
                                                        <li><i className="fa fa-check-circle-o" />Image Optimization</li>
                                                        <li><i className="fa fa-check-circle-o" />Inclusion of anchors Tags</li>
                                                        <li><i className="fa fa-check-circle-o" />Inclusion of anchors Indexing
                                                            Modifications</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Tracking &amp; Analysis</b>
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Google Places Inclusions</li>
                                                        <li><i className="fa fa-check-circle-o" />Google Analytics Installation
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Google Webmaster Installation
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Call To Action Plan</li>
                                                        <li><i className="fa fa-check-circle-o" />Creation of Sitemaps</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Reporting</b></li>
                                                        <li><i className="fa fa-check-circle-o" />Monthly Reporting</li>
                                                        <li><i className="fa fa-check-circle-o" />Recommendation</li>
                                                        <li><i className="fa fa-check-circle-o" />Email Support</li>
                                                        <li><i className="fa fa-check-circle-o" />Phone Support</li>
                                                        <li><i className="fa fa-check-circle-o" /><b>Off Page Optimization</b>
                                                        </li>
                                                        <li><i className="fa fa-check-circle-o" />Social Bookmarking</li>
                                                        <li><i className="fa fa-check-circle-o" />Slide Share Marketing</li>
                                                        <li><i className="fa fa-check-circle-o" />Forums/FAQ’s</li>
                                                        <li><i className="fa fa-check-circle-o" />Link Building</li>
                                                        <li><i className="fa fa-check-circle-o" />Directory Submission</li>
                                                        <li><i className="fa fa-check-circle-o" />Local Business Listings</li>
                                                    </ul>
                                                </div>
                                                <div className="text-center package-mid">

                                                    <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order Now </button>

                                                </div>
                                                <div className="package-footer d-flex justify-content-between">
                                                    <div className="bd-right">
                                                        <p>Share your idea?</p>
                                                        <a href="tel:+1234567891">1234567891</a>
                                                    </div>
                                                    <div>
                                                        <p>Want to discuss?</p>
                                                        <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-video" role="tabpanel" aria-labelledby="nav-video-tab">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Startup Video Package</h4>
                                            <span className="h6"><del>$1598.00 </del>Only</span>
                                            <span className="h5">$499.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                startups</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />30 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>

                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />4 weeks Delivery</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Revisions
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Classic Video Package</h4>
                                            <span className="h6"><del>$2198.00 </del>Only</span>
                                            <span className="h5">$999.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                corporate ladder.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />60 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 weeks Delivery</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Premium Video Package</h4>
                                            <span className="h6"><del>$2998.00 </del>Only</span>
                                            <span className="h5">$1499.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />90 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />6 Weeks Delivery</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Unlimited Video Package</h4>
                                            <span className="h6"><del>$4598.00 </del>Only</span>
                                            <span className="h5">$2299.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />120 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-shopfiy" role="tabpanel" aria-labelledby="nav-shopfiy-tab">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Starter Package</h4>
                                            {/*<span class="h6"><del>$1598.00 </del>Only</span>*/}
                                            <span className="h5">$699</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                startups</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom designed
                                                    Homepage (1x concepts)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Custom designed
                                                    Inner pages</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Upto 25 to 50
                                                    Products</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Upto 7 Categories
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                    System</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sales &amp; Inventory
                                                    Management</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Mini Shopping Cart
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Payment Gateway
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Easy Product Search
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Premium Stock
                                                    Photos</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Promotional Banners
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) responsive compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross browser
                                                    (Chrome, Firefox, Safari, etc) compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />30 Days Free
                                                    Maintenance (Post-launch support)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Industry Specified
                                                    Team of Expert Designers and Developers</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Dedicated Account
                                                    Manager</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />24/7 Customer
                                                    Support</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Unlimited Revisions
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />100% Satisfaction
                                                    Guarantee</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Unique Design
                                                    Guarantee</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Money Back
                                                    Guarantee *</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />100% Ownership
                                                    Rights</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Complete Brand
                                                    Identity&nbsp; <strong>($199)</strong></li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Live Chat/Bot Chat
                                                    Integration&nbsp; <strong>($249)</strong></li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Professional Package</h4>
                                            {/*<span class="h6"><del>$2198.00 </del>Only</span>*/}
                                            <span className="h5">$1399</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                corporate ladder.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom designed
                                                    Homepage (2x concepts)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />10 Custom designed
                                                    Inner pages</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive and
                                                    Dynamic Website Design</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Upto 50 - 250
                                                    Products</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Upto 10 Categories
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />15 Premium Stock
                                                    Photos</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />8 Promotional Banners
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />1 Landing Page Design
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Customer Login/Signup
                                                    Area</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Database
                                                    Creation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Live Chat/Bot Chat
                                                    Integration (Optional)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Shipping Merchant
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Dropshipping
                                                    Integration (Optional)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                    System</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sales &amp; Inventory
                                                    Management</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Product offers
                                                    (wish-list, discount options, coupon codes)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Product rating &amp;
                                                    reviews</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Easy Product Search
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Product sorting
                                                    (Newest, Featured, Popular, Best Seller)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Full Shopping Cart
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Payment Module
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />3rd Party API
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Customized Filters
                                                    for Refined Search</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />SEO friendly coding
                                                    (Meta-tags, Meta Titles, Meta Description, Keywords, W3C compliant etc)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Search Engine
                                                    Indexing (Google, Yahoo, Bing, etc)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) responsive compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross browser
                                                    (Chrome, Firefox, Safari, etc) compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Security plugins</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 5 Business Email
                                                    Address</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 90 Days Free
                                                    Maintenance (Post-launch support)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> How-to-use Training
                                                    Manual for CMS</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Industry Specified
                                                    Team of Expert Designers and Developers</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Dedicated Account
                                                    Manager</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 24/7 Customer
                                                    Support</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Unlimited Revisions
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Satisfaction
                                                    Guarantee</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Unique Design
                                                    Guarantee</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Money Back
                                                    Guarantee *</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Ownership
                                                    Rights</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Marketplace
                                                    Development - <strong>($749)</strong></li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" /> Multi-Currency
                                                    Support - <strong>($249)</strong></li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Business Package</h4>
                                            {/*<span class="h6"><del>$2998.00 </del>Only</span>*/}
                                            <span className="h5">$2799</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom designed
                                                    Homepage (3x concepts)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />20 Custom designed
                                                    Inner pages</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Interactive and
                                                    Dynamic Website Design</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Upto 250 - 1000
                                                    Products</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Upto 20 Categories
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />25 Premium Stock
                                                    Photos</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />15 Promotional
                                                    Banners</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />2 Landing Pages
                                                    Design</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Customer
                                                    Login/Signup Area</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Complete Database
                                                    Creation</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Live Chat/Bot Chat
                                                    Integration (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Shipping Merchant
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Multi-Currency
                                                    Support (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Dropshipping
                                                    Integration (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                    System</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Sales &amp;
                                                    Inventory Management</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Manage orders (track
                                                    order, billing history, order status, automated invoicing)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product offers
                                                    (wish-list, discount options, coupon codes)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Multiple Product
                                                    variation (Color, Size, Quantity, and other attributes)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Intelligent search
                                                    system with filtering options (search by price, categories, Styles, etc)
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product sorting
                                                    (Newest, Featured, Popular, Best Seller)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product rating &amp;
                                                    reviews</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Easy Product Search
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Full Shopping Cart
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Payment Module
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Guest Checkout</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />3rd Party API
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />1 year free hosting
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />1 year free domain
                                                    registration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />SEO friendly coding
                                                    (Meta-tags, Meta Titles, Meta Description, Keywords, W3C compliant etc)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Search Engine
                                                    Indexing (Google, Yahoo, Bing, etc)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) responsive compatibility</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Cross browser
                                                    (Chrome, Firefox, Safari, etc) compatibility</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Email Marketing
                                                    Campaigns</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Security plugins
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Analytic
                                                    Installation</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Webmaster
                                                    Tool</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 5 Business Email
                                                    Address</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 180 Days Free
                                                    Maintenance (Post-launch support)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> How-to-use Training
                                                    Manual for CMS</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Industry Specified
                                                    Team of Expert Designers and Developers</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Dedicated Account
                                                    Manager</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 24/7 Customer
                                                    Support</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Unlimited Revisions
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Satisfaction
                                                    Guarantee</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Unique Design
                                                    Guarantee</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Money Back
                                                    Guarantee *</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Ownership
                                                    Rights</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Marketplace
                                                    Development - <strong>($749)</strong></li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 30 Seconds
                                                    Explainer Video - <strong>($349)</strong></li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Enterprise Package</h4>
                                            {/*<span class="h6"><del>$4598.00 </del>Only</span>*/}
                                            <span className="h5">$4,599</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom designed
                                                    Homepage (6x concepts)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Custom
                                                    designed Inner pages</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom Made,
                                                    Interactive, Dynamic &amp; User Friendly Design</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />High End UI|UX</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom Coding &amp;
                                                    Development</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Marketplace
                                                    Development (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                    System</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Sales &amp;
                                                    Inventory Management</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Products
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Categories
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Premium
                                                    Stock Photos</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited
                                                    Promotional Banners</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />2 Landing Pages
                                                    Design</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) responsive compatibility</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product Detail Page
                                                    Design</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Bulk CSV Products
                                                    Upload</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product Return
                                                    Management System</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Reward pointing
                                                    system</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom Calculators
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product offers
                                                    (wish-list, discount options, coupon codes)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Multiple Product
                                                    variation (Color, Size, Quantity, and other attributes)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Intelligent search
                                                    system with filtering options (search by price, categories, Styles etc)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product sorting
                                                    (Newest, Featured, Popular, Best Seller)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Shipping Merchant
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Dropshipping
                                                    Integration (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Customer
                                                    Login/Signup Area</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Complete Database
                                                    Creation</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Full Shopping Cart
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Product Rating &amp;
                                                    Reviews</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Automated Emails
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Automated Reminders
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Email Marketing
                                                    Campaigns</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Tell a Friend
                                                    Feature</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Guest Checkout</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Shipping Labels</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />News &amp;
                                                    promotions</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Downloadable items
                                                    (eBooks, PDFs, Podcast, Videos)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Easy Product Search
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Payment Gateway
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Multi-currency
                                                    Support</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Live Chat/Bot Chat
                                                    Integration (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Social Media Live
                                                    Feeds Widget (Facebook, Instragram, Pinterest)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />3rd Party API
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Signup Automated
                                                    Email Authentication</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Signup Area (For
                                                    Newsletters, Offers etc.)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Search Engine
                                                    Indexing (Google, Yahoo, and Bing)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Module-wise
                                                    Architecture</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Extensive Admin
                                                    Panel</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />404 Redirect</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />XML Sitemap</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Parallax Scrolling
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Security plugins
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />5 year free hosting
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />5 year free domain
                                                    registration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />SEO friendly coding
                                                    (Meta-tags, Meta Titles, Meta Description, Keywords, etc)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Analytic
                                                    Installation</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Webmaster
                                                    Tool</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Cross Browser
                                                    Compatible</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />48-72 Hours
                                                    Turnaround Time</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Unlimited Business
                                                    Email Address</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 1 Year Free
                                                    Maintenance (Post-launch support)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Industry Specified
                                                    Team of Expert Designers and Developers</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Dedicated Account
                                                    Manager</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 24/7 Customer
                                                    Support</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Unlimited Revisions
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Satisfaction
                                                    Guarantee</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Unique Design
                                                    Guarantee</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Money Back
                                                    Guarantee *</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> 100% Ownership
                                                    Rights</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Professional
                                                    Content/Copywriting – $1,000 (Up to 20 Pages) – (Per Page $50)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" /> Search Engine
                                                    Optimization (3 Months - 50 Keywords) - $1,350</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-wordpress" role="tabpanel" aria-labelledby="nav-wordpress-tab">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Professional Package</h4>
                                            {/*<span class="h6"><del>$1598.00 </del>Only</span>*/}
                                            <span className="h5">$399</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                startups</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Designed
                                                    Homepage (1x concepts)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Custom Designed
                                                    inner pages</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Premium Stock
                                                    Photos</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />3 Custom Banner
                                                    Design</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive &amp;
                                                    Animated Slider Banner</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Contact Us Form</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross-Browser
                                                    (Chrome, Firefox, Safari, etc.) Compatibility.</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Security Plugins</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />48-72 Hours
                                                    Turnaround Time</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Business Package</h4>
                                            {/*<span class="h6"><del>$2198.00 </del>Only</span>*/}
                                            <span className="h5">$899</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                corporate ladder.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Designed
                                                    Homepage (2x concepts)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />10 Custom Designed
                                                    Inner Pages</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                    System (CMS) Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross-Platform
                                                    (Desktop, iPhone, Android, etc.) Responsive Compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive &amp;
                                                    Animated Slider Banner</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />8 Premium Stock
                                                    Photos</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Custom Banner
                                                    Designs</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Contact/Query Form
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />1 Landing Page Design
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Newsletter
                                                    Subscription (Optional)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Business Email
                                                    Address</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross-Browser
                                                    (Chrome, Firefox, Safari, etc.) Compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Parallax Scrolling
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Security plugins</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross Browser
                                                    Compatible</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />48-72 Hours
                                                    Turnaround Time</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Corporate Package</h4>
                                            {/*<span class="h6"><del>$2998.00 </del>Only</span>*/}
                                            <span className="h5">$1,599</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Designed
                                                    Homepage (3x Concepts)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />15 Custom Designed
                                                    Inner Pages</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive and
                                                    Dynamic Website Design</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) Responsive Compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Content Management
                                                    System (CMS) Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Online
                                                    Reservation/Appointment/Scheduling Tools</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Striking Hover
                                                    Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider Banner</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />12 Premium Stock
                                                    Photos</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />8 Custom Banner
                                                    Designs</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />1 Landing Page Design
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Online Payment
                                                    Integration (Optional)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Dynamic Forms
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Signup Area (For
                                                    Newsletters, Offers etc.)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media Live
                                                    Feeds Widget (Facebook, Instragram, Pinterest)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Business Email
                                                    Address</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Search Engine
                                                    Indexing (Google, Yahoo, and Bing)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />SEO friendly coding
                                                    (Meta-tags, Meta Titles, Meta Description, Keywords, etc)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Parallax Scrolling
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Security Plugins</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Analytic
                                                    Installation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Webmaster Tool
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross Browser
                                                    Compatible</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />48-72 Hours
                                                    Turnaround Time</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Enterprise Package</h4>
                                            {/*<span class="h6"><del>$4598.00 </del>Only</span>*/}
                                            <span className="h5">$2,799</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom designed
                                                    Homepage (6x concepts)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />20 Custom designed
                                                    Inner pages</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom Made,
                                                    Interactive, Dynamic &amp; User Friendly Design</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom Wordpress/PHP
                                                    Development</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Customized CMS
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) responsive compatibility</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Striking Hover
                                                    Effects</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider Banner</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />15 Premium Stock
                                                    Photos</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />10 Custom Banner
                                                    Designs</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />2 Landing Page
                                                    Designs</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Custom Dynamic Forms
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Online Reservation/
                                                    Appointment/ Scheduling Tool</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Online Payment
                                                    Integration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Live Chat/Bot Chat
                                                    Integration (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Multi Lingual</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />3rd Party API
                                                    Integrations</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Signup Area (For
                                                    Newsletters, Offers etc.)
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Downloadable items
                                                    (eBooks, PDFs, Podcast, Videos)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Social Media Live
                                                    Feeds Widget (Facebook, Instragram, Pinterest)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />SEO friendly coding
                                                    (Meta-tags, Meta Titles, Meta Description, Keywords, etc)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Search Engine
                                                    Indexing (Google, Yahoo, and Bing)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Mailing List Plugin
                                                    (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />2 Years Domain
                                                    Registration (Optional)</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />10 Business Email
                                                    Address</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Security plugins
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Analytic
                                                    Installation</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Webmaster
                                                    Tool</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Cross Browser
                                                    Compatible</li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li> <i className="fa fa-check-circle-o" aria-hidden="true" />48-72 Hours
                                                    Turnaround Time</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Enterprise Plus Package</h4>
                                            {/*<span class="h6"><del>$4598.00 </del>Only</span>*/}
                                            <span className="h5">$4,599</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom designed
                                                    Homepage (Unlimited concepts)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Custom
                                                    designed Inner pages</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Made,
                                                    Interactive, Dynamic &amp; User Friendly Design</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />High End UI|UX</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Coding &amp;
                                                    Development</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Customized CMS
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross platform
                                                    (Desktop, iPhone, Android, etc) responsive compatibility</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Striking Hover
                                                    Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Interactive jQuery
                                                    Slider Banner</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Premium
                                                    Stock Photos</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Custom
                                                    Banner Designs</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />2 Landing Page
                                                    Designs</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Custom Dynamic Forms
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Enterprise Web
                                                    Portals (multi-user accounts and roles)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />User Signup Area</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Client/User Dashboard
                                                    Area</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Database
                                                    Creation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Extensive Admin Panel
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Online
                                                    Reservation/Appointment/Scheduling Tool</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Online Payment
                                                    Integration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Live Chat/Bot Chat
                                                    Integration (Optional)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Downloadable items
                                                    (eBooks, PDFs, Podcast, Videos)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Multi Lingual</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />3rd Party API
                                                    Integrations</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Signup Area (For
                                                    Newsletters, Offers etc.)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Search Bar for Easy
                                                    Search</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media
                                                    Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Social Media Live
                                                    Feeds Widget (Facebook, Instragram, Pinterest)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Search Engine
                                                    Indexing (Google, Yahoo, and Bing)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />SEO friendly coding
                                                    (Meta-tags, Meta Titles, Meta Description, Keywords, W3C compliant etc)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />On-page SEO
                                                    configuration</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />404 Redirect</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />XML Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Mailing List Plugin
                                                    (Optional</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 Years Domain
                                                    Registration (Optional)</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />10 Business Email
                                                    Address</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Parallax Scrolling
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Fast Load Time</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Security plugins</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Analytic
                                                    Installation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Webmaster Tool
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Google Friendly
                                                    Sitemap</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />W3C Certified HTML
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Cross Browser
                                                    Compatible</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Complete Deployment
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />48-72 Hours
                                                    Turnaround Time</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-content" role="tabpanel" aria-labelledby="nav-content-tab">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Startup Video Package</h4>
                                            <span className="h6"><del>$1598.00 </del>Only</span>
                                            <span className="h5">$499.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                startups</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />30 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>

                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />4 weeks Delivery</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Revisions
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Classic Video Package</h4>
                                            <span className="h6"><del>$2198.00 </del>Only</span>
                                            <span className="h5">$999.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                corporate ladder.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />60 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 weeks Delivery</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Premium Video Package</h4>
                                            <span className="h6"><del>$2998.00 </del>Only</span>
                                            <span className="h5">$1499.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />90 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />6 Weeks Delivery</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Unlimited Video Package</h4>
                                            <span className="h6"><del>$4598.00 </del>Only</span>
                                            <span className="h5">$2299.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />120 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-white" role="tabpanel" aria-labelledby="nav-white-tab">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Startup Video Package</h4>
                                            <span className="h6"><del>$1598.00 </del>Only</span>
                                            <span className="h5">$499.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">Suitable for newly formed organizations or small incubated
                                                startups</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />30 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>

                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />4 weeks Delivery</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Unlimited Revisions
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Kick Starter Package  - $199/m" data-price={199.00}>Order Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Classic Video Package</h4>
                                            <span className="h6"><del>$2198.00 </del>Only</span>
                                            <span className="h5">$999.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For medium-sized stable organizations looking to climb up the
                                                corporate ladder.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />60 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />5 weeks Delivery</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Scaling Plan - $399/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Premium Video Package</h4>
                                            <span className="h6"><del>$2998.00 </del>Only</span>
                                            <span className="h5">$1499.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />90 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />6 Weeks Delivery</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="package-box grey-bg" data-aos="fade-up" data-aos-duration={1000}>
                                        <div className="plan-head text-center">
                                            <h4>Unlimited Video Package</h4>
                                            <span className="h6"><del>$4598.00 </del>Only</span>
                                            <span className="h5">$2299.00 USD</span>
                                        </div>
                                        <div className="package-list">
                                            <p className="suit-text">For pre-established businesses that aim to maintain their
                                                presence and claim the crown.</p>
                                            <ul className="pkg-opt mt-3 mb-3">
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />120 Second Video</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Professional Script
                                                </li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Sample Theme</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Storyboard</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Animation</li>
                                                <li><i className="fa fa-check-circle-o" aria-hidden="true" />Voice - Over &amp;
                                                    Sound Effects</li>
                                            </ul>
                                        </div>
                                        <div className="text-center package-mid">

                                            <button type="button" className="btn btn-info btn-lg open_modal" data-pack_id="Link Building - Venture Plan - $699/m" data-price={199.00}>Order
                                                Now </button>

                                        </div>
                                        <div className="package-footer d-flex justify-content-between">
                                            <div className="bd-right">
                                                <p>Share your idea?</p>
                                                <a href="tel:+1234567891">1234567891</a>
                                            </div>
                                            <div>
                                                <p>Want to discuss?</p>
                                                <a className="live-btn" href="javascript:;" onClick="$zopim.livechat.window.toggle()">Live Chat Now...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
                </section>

            <section className="logo-rotate timeline-section all-section">
                <div className="container">
                    <div className="col-md-10 mx-auto">
                        <div className="section-top  text-center" data-aos="fade-down" data-aos-duration={1000}>
                            <div className="top-head mx-auto">
                                <span className="head-before" />
                                <span className="head-upper" />
                                <span className="head-after" />
                            </div>
                            <h3 className="section-head">Our strategy for elevating your business</h3>
                        </div>
                    </div>
                    <div className="timeline-middlepath">
                        <div className="row">
                            <div className="col-md-2 mx-auto">
                                <div className="timeline-middle">
                                    <img src="images/timeline-rocket.png" alt="Image" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="time-box">
                        <div className="row order-change">
                            <div className="col-md-5 col-sm-6 col-9">
                                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                                    <span>01</span>
                                    <span className="box-head text-uppercase">Analyzing the competition</span>
                                    <p>It’s important to know your competition in order to beat them. We implement the latest
                                        technology and the most innovative and proven methods to grow your brand after
                                        researching and using SEO optimization. </p>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                                <figure data-aos="zoom-in" data-aos-duration={1000}>
                                    <img src="images/timeline1.jpg" alt="Image" className="img-responsive" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="time-box  right-time">
                        <div className="row ">
                            <div className="col-md-5 col-sm-6 col-3">
                                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                                    <img src="images/timeline2.png" alt="Image" className="img-responsive" />
                                </figure>
                            </div>
                            <div className="col-md-5 col-sm-6  col-9 offset-md-2">
                                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                                    <span>02</span>
                                    <span className="box-head text-uppercase">Curating Captivating Content</span>
                                    <p>The 3 C’s of SEO work like magic. They stand for Curating Captivating Content and that is
                                        exactly what we aim to do for our clients in order to keep you ahead of the competition.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="time-box">
                        <div className="row order-change">
                            <div className="col-md-5 col-sm-6 col-9">
                                <div className="time-desc" data-aos="fade-right" data-aos-duration={1000}>
                                    <span>03</span>
                                    <span className="box-head text-uppercase">Increasing Conversions</span>
                                    <p>We don’t just work to increase traffic for your site but we also come up with effective
                                        and foolproof ways to convert your visitors into clients and increase your sales. </p>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-6 col-3  offset-md-2">
                                <figure data-aos="zoom-in" data-aos-duration={1000}>
                                    <img src="images/timeline3.png" alt="Image" className="img-responsive" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="time-box  right-time">
                        <div className="row ">
                            <div className="col-md-5 col-sm-6 col-3">
                                <figure data-aos="zoom-in" data-aos-duration={1000} className="float-right">
                                    <img src="images/timeline4.png" alt="Image" className="img-responsive" />
                                </figure>
                            </div>
                            <div className="col-md-5 col-sm-6 col-9 offset-md-2">
                                <div className="time-desc" data-aos="fade-left" data-aos-duration={1000}>
                                    <span>04</span>
                                    <span className="box-head text-uppercase">Boosting Your ROI</span>
                                    <p>Our services are tailored with the sole object of ensuring success for your business.
                                        With more leads, you get a higher ROI!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




     <Contact/>

    <Footer />
    </>

		)
}