import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import PortfolioCompo from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';



export default function Portfolio(){
	return(
    <> 
    <Header />
    
      <section className="portfoliosec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <div className="innerbannertext">
                <h1><span>Showcasing Our Expert Work</span>
                  Featured Masterpieces
                </h1>
                <p>webcraftsolutions has worked for almost every industry and understands the requirement of
                  every specific market niche. The skills are quite eminent in our portfolio, and these
                  designs are enough for anyone to get an idea about the quality standards of our work.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    <PortfolioCompo/>
    <Contact/>

    <Footer />
    </>
		)
}