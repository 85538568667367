
import $ from 'jquery';
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export default function Footer() {

  // function slideForm() {
  //   const floatbutton = document.querySelector('.floatbutton');
  //   var res = floatbutton.classList.contains('active');
  //   if (res) {
  //     floatbutton.classList.remove('active');
  //   } else {
  //     floatbutton.classList.add('active');
  //   }
  // }

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 100) {
      window.$(".backtop").addClass("show");
    } else {
      window.$(".backtop").removeClass("show");
    }
  });

  function backTop() {
    $("html, body").animate({
      scrollTop: 0
    }, "300");
  }


  // Sendmail floatForm
  const bannerF = useRef();
  const bannerForm = (e) => {
  e.preventDefault();
    emailjs.sendForm('service_8iuyr9c', 'template_3s65mss', bannerF.current, 'mFs_PVy5BMJMtOR91')
      .then((result) => {
          alert('Submit Successfully.');
          document.getElementById("bannerForm").reset();
          document.getElementById('analyse-modal').click();
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (


    <div>
      <section className="contact_footer_details">
        <div className="container">
          <div className="row">
            <div className="col-sm-4  bd-left">
              <div className="contact_footer_box py-5">
                <span data-aos="fade-right" className="h4" data-aos-duration={1000}>Email</span>
                <div className="d-flex" data-aos="fade-up" data-aos-duration={1000}>
                  <i className=" fa fa-envelope" aria-hidden="true" />
                  <span>
                    <a href="mailto:info@webcraftsolutions.com">info@webcraftsolutions.com</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="contact_footer_box py-5">
                <span data-aos="fade-right" className="h4" data-aos-duration={1000}>Contact Us</span>
                <div className="d-flex" data-aos="fade-up" data-aos-duration={1000}>
                  <i className="fa fa-map" aria-hidden="true" />
                  <span>
                    <a href="javascript:;"  >ABC Dummy, 5th Floor, New York
                      City, NY, 10013, USA</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="contact_footer_box py-5">
                <span data-aos="fade-right" className="h4" data-aos-duration={1000}>Phone</span>
                <div className="d-flex" data-aos="fade-up" data-aos-duration={1000}>
                  <i className="fa fa-phone" aria-hidden="true" />
                  <span>
                    <a href="tel:+1234567891"><span>Sales</span>1234567891</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ">
              <div className="footer-item text-center text-lg-left">
                <div className="footer_logo">
                  <a href="Home">
                    <img src="images/logo.png" className="img-responsive" alt="" />
                  </a>
                </div>
                <p>We are here to give you an exceptional experience. Through our help, you will see your website
                  reach a lot of new customers. You have visited the best custom web development agency for your
                  business, congratulations.</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="footer-item">
                <span className="h2">Quick Links</span>
                <ul>
                  <li><a href="Home">Home</a></li>
                  <li><a href="about">About</a></li>
                  <li><a href="portfolio">Portfolio</a></li>
                  <li><a href="packages">Packages</a></li>
                  <li><a href="contact">Contact Us</a></li>
                  <li><a href="faq">FAQ's</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="footer-item">
                <span className="h2">Services</span>
                <ul>
                  <li><a href="logo-design">Logo Design</a></li>
                  <li><a href="ecommerce">Ecommerce Development</a></li>
                  <li><a href="search-engine-optimization">Website Development</a></li>
                  <li><a href="wordpress">WordPress</a></li>
                  <li><a href="shopify">Shopify</a></li>
                  <li><a href="video-animation">Video Animation</a></li>
                  <li><a href="search-engine-optimization">SEO Optimization</a></li>
                  <li><a href="social-media-marketing"> Social Media Marketing</a></li>
                  <li><a href="content-marketing">Content Marketing</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div className="footer-item social_icon">
                <span className="h2">Follow Us On</span>
                <ul>
                  <li>
                    <a href="javascript:;"><img src="images/socail-icon-img-01.png" alt="" /></a>
                  </li>
                  <li>
                    <a href="javascript:;"><img src="images/socail-icon-img-02.png" alt="" /></a>
                  </li>
                  <li>
                    <a href="javascript:;"><img src="images/socail-icon-img-03.png" alt="" /></a>
                  </li>
                  <li>
                    <a href="javascript:;"><img src="images/socail-icon-img-04.png" alt="" /></a>
                  </li>
                </ul>
              </div>
              <div className="top_seo_img">
                <img src="images/top-seo-img.png" className="img-responsive" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="copy_right_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="copy_right_content">
                  <p>Copyright © 2023 Web Craft Solutions. All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="term_links">
                  <ul>
                    <li><a href="terms">Terms &amp; Conditions</a></li>
                    <li><a href="privacy">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="floatbutton">
        <div className="btns_wrap">
          <a className="chat_wrap orange-gradient" href="javascript:;" onClick="$zopim.livechat.window.toggle()">
            <span className="icoo"><i className="fa fa-comment" /></span>
            <span>Chat With Us</span>
          </a>
          <a href="tel:+1234567891" className="call_wrap orange-gradient">
            <span className="icoo"><i className="fa fa-phone" /></span>
            <span> 1234567891 </span>
          </a>
        </div>
        {/* <div className="clickbutton orange-gradient" onClick={slideForm}>
          <div className="crossplus"> LETS GET STARTED  </div>
        </div> */}

        {/* <div className="banner-form">
          <h3>Sign Up Now &amp; <br /><strong>Let’s Get Started</strong></h3>
          <div className="banform">
            <div className="container">
              <div className="row">
                <div className="ban-form">
                  <form className="form-get-quote" id="floatForm" ref={floatF} onSubmit={floatForm}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="fldset">
                          <input name="user_name" type="text" placeholder="Enter your name *" required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="fldset">
                          <input type="email" name="user_email" placeholder="Enter email here *" required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="fldset">
                          <input className="phoneNum" name="user_phone" type="text" placeholder="Phone Number *" required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="fldset">
                          <textarea name="message" required rows={7} placeholder="Talk About Your Project" defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="fldset">
                          <input name="submit" type="submit" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </div>

      <a href="tel:+1234567891" className="callusnow"><i className="fa fa-phone" /></a>
      <div className="modal fade" id="analyse-modal" tabhome={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body full-modal-body">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="modal-text text-center full-pg-modal">
                    <img src="images/modal-img.png" alt="Image" className="img-responsive" />
                    <h3 className="mt-4 text-white section-head">Wait! Looking for an Amazing Offer?</h3>
                    <p className="text-white">Get Your Design Now</p>
                    <form className="banner-form four-inputs form-get-quote" id="bannerForm" ref={bannerF} onSubmit={bannerForm}>
                      <input type="text" placeholder="Enter Name Here" name="user_name" required />
                      <input type="email" placeholder="Enter Email Here" name="user_email" required />
                      <input type="text" className="phoneNum" placeholder="Enter Phone Number" name="user_phone" required />
                      <input type="text" placeholder="message" name="message" required />
                      <button className="orange-gradient theme-btn" type="submit">Get a Free Quote</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="backtop" onClick={backTop}>
        <a id="button"><img src="images/timeline-rocket.png" alt="Image" className="img-responsive" /></a>
        <span>Back To Top</span>
      </div>

     
      </div>

  );
}