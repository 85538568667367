

export default function Support() {
     return (

      <section className="logo-rotate2 exp-section all-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-top text-center" data-aos="fade-down" data-aos-duration={1000}>
                <div className="top-head mx-auto">
                  <span className="head-before" />
                  <span className="head-upper" />
                  <span className="head-after" />
                </div>
                <h3 className="section-head">We Will Support Your Business With A Vision Of Success.</h3>
                <p>At Web Craft Solutions, your project is in the most capable hands and you can rely on our
                  skills to take your business around the world.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 p-0">
              <div className="exp-box text-center">
                <img src="images/exp1.png" alt="Image" className="img-responsive" data-aos="zoom-in" data-aos-duration={1000} />
                <div className="text" data-aos="fade-up" data-aos-duration={1000}>
                  <span className="box-head mt-4">Customized Strategy </span>
                  <p>We will devise a completely customized strategy for your unique and individual business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 p-0">
              <div className="exp-box text-center">
                <img src="images/exp2.png" alt="Image" className="img-responsive" data-aos="zoom-in" data-aos-duration={1000} />
                <div className="text" data-aos="fade-up" data-aos-duration={1000}>
                  <span className="box-head mt-4">Smooth Optimization</span>
                  <p>Our process will be smooth and you will know that work is done with effective results.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 p-0">
              <div className="exp-box text-center border-0">
                <img src="images/exp3.png" alt="Image" className="img-responsive" data-aos="zoom-in" data-aos-duration={1000} />
                <div className="text" data-aos="fade-up" data-aos-duration={1000}>
                  <span className="box-head mt-4">Data-Based Approach</span>
                  <p>You can rely on us because our approach is proven and based on customer behavior data.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ex-btns text-center mt-5" data-aos="fade-up" data-aos-duration={1000}>
            <a className="dark-gradient theme-btn" href="javascript:;" data-toggle="modal" data-target="#analyse-modal">Request
              a Custom Quote</a>
            <p>Any Question? <a href="javascript:;">Talk to our Expert</a></p>
          </div>
        </div>
      </section>
      
    );

}