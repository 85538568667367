
import React, { Component }  from 'react';
import Home from '../src/Pages/Home';
import About from '../src/Pages/About';
import Portfolio from '../src/Pages/Portfolio';
import Packages from '../src/Pages/Packages';
import Contact from '../src/Pages/Contact';
import Faq from '../src/Pages/Faq';
import LogoDesign from '../src/Pages/LogoDesign';
import WebsiteDevelopment from '../src/Pages/WebsiteDevelopment';
import Ecommerce from '../src/Pages/Ecommerce';
import Shopify from '../src/Pages/Shopify';
import Wordpress from '../src/Pages/Wordpress';
import VideoAnimation from '../src/Pages/VideoAnimation';
import Seo from '../src/Pages/Seo';
import Smm from '../src/Pages/Smm';
import ContentMartketing from '../src/Pages/ContentMartketing';
import Terms from '../src/Pages/TermsCondition';
import Privacy from '../src/Pages/Privacy';





import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
           <Route index element={<Home/>}/>
           <Route path="/home" element={<Home/>}/>
           <Route path="/about" element={<About/>}/>
           <Route path="/portfolio" element={<Portfolio/>}/>
           <Route path="/packages" element={<Packages/>}/>
           <Route path="/faq" element={<Faq/>}/>
           <Route path="/contact" element={<Contact/>}/>
           <Route path="/logo-design" element={<LogoDesign/>}/>
           <Route path="/website-development" element={<WebsiteDevelopment/>}/>
           <Route path="/ecommerce" element={<Ecommerce/>}/>
           <Route path="/shopify" element={<Shopify/>}/>
           <Route path="/wordpress" element={<Wordpress/>}/>
           <Route path="/video-animation" element={<VideoAnimation/>}/>
           <Route path="/search-engine-optimization" element={<Seo/>}/>
           <Route path="/social-media-marketing" element={<Smm/>}/>
           <Route path="/content-marketing" element={<ContentMartketing/>}/>
           <Route path="/terms" element={<Terms/>}/>
           <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
      </BrowserRouter> 
    </>   
  
  );
}

export default App;
