import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/Portfolio';
import Contact from 
'../Components/Contact';
import { useEffect } from 'react';
import All_BannerForm from '../Components/All_BannerForm';



export default function VideoAnimation(){

  setTimeout(() => {
    document.getElementById('motionGraphics').click();
    document.getElementById('nav-video-tab').click()
  }, 1000);

  useEffect(() => {

    window.$(".tablist ul li a").click((function() {
    var t = window.$(this).attr("data-target");
    return window.$(".tablist ul li a").removeClass("active"),
     window.$(this).addClass("active"),
      window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
}))
document.title = 'Web Craft Solutions | Video Animation';
},[]);

	return(
    <> 
    <Header />
    
    <section id="social-banner" className="services-banner inner-banner orange-gradient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 mx-auto">
              <div className="inner-banner-text text-center " data-aos="fade-down" data-aos-duration={1000}>
                <h1>Animated videos that Propel conversions</h1>
                <p className="text-white">We have a great team of animators and graphic designers who know how to
                  create masterpieces through animation. We cater to all your creative needs and aim to
                  deliver an expertly crafted animated video with striking visuals and originality. Our
                  premium quality videos work best to deliver your message to your target audience and
                  captivate them for the long run. We ensure </p>
                <img src="images/partners.png" data-aos="zoom-in" data-aos-duration={1000} data-aos-delay={200} data-aos-offset={0} className="brand-img img-responsive" alt="Banner" />
              </div>
            </div>
            <div className="col-md-12">
              <All_BannerForm/>
            </div>
          </div>
        </div>
    </section>


     <Packages/>
     <Portfolio/>

     <section className="web-manage-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="web-img">
                <img loading="lazy" src="images/web-img.png" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-text">
                <h2>
                  Secure, Fast, &amp; High <br /> Performing Websites
                </h2>
                <p>
                  Get your dream WordPress website with us that offers your business more visitors, leads, and
                  profits. Web Craft Solutions is a full-service website development firm that provides
                  turn-key WordPress development solutions for businesses. We handle everything for our valued
                  clients, from design and development to deployment and optimization.
                </p>
              </div>
              <div className="btn-box">
                <a href="javascript:;" className="requestbtn" data-toggle="modal" data-target="#analyse-modal">Request a
                  Quote</a>
                <a href="javascript:;" className="livechatbtn">live chat <i className="fa fa-arrow-circle-o-right" aria-hidden="true" /> </a>
              </div>
            </div>
          </div>
        </div>
      </section>


     <Contact/>

    <Footer />
    </>

		)
}